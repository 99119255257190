import useStyles from "./styles";
import { FC } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { Icon } from "../Icon/Icon";
import { colors } from "../../styles/colors";

type Props = {
  width?: string | number;
  height?: string | number;
  borderRadius?: string | number;
  iconWidth?: string | number | undefined;
  iconHeight?: string | number | undefined;
  iconName?: string;
  imageUrl?: string;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
};

export const CustomDropZoneUpload: FC<Props> = ({
  width,
  height,
  borderRadius,
  iconHeight,
  iconWidth,
  iconName,
  imageUrl,
  onDrop,
}) => {
  const classes = useStyles({ width, height, borderRadius });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    multiple: false,
    maxSize: 10 * 1024 * 1024, // 10 megabytes in bytes
  });

  return (
    <div className={classes.uploadFile} {...getRootProps()}>
      <input {...getInputProps()} />
      {imageUrl ? (
        <img src={imageUrl} alt="Uploaded" className={classes.imagesStyles} />
      ) : (
        <Icon
          name={iconName ? iconName : "photo"}
          width={iconWidth ? iconWidth : "38px"}
          height={iconHeight ? iconHeight : "38px"}
          color={colors.greydark}
        />
      )}
    </div>
  );
};
