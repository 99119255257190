import { FC } from "react";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router";
import { colors } from "../../styles/colors";
import { Icon } from "../../components/Icon/Icon";

type props = {
  vehicle: any;
};

export const ButtonsListVehicle: FC<props> = ({ vehicle }) => {
  const navigate = useNavigate();
  const onClickVehicle = () => {
    navigate(`/retranscription?immatriculation=${vehicle.immatriculation}`);
  };

  return (
    <IconButton onClick={onClickVehicle}>
      <Icon name="eye" width={"24px"} height={"24px"} color={colors.BLACK} />
    </IconButton>
  );
};
