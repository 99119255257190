export const setCurrentCompanie = (idCompanie: number) => {
  localStorage.setItem("currentCompanie", JSON.stringify(idCompanie));
};

export const getCurrentCompanie = () => {
  return JSON.parse(localStorage.getItem("currentCompanie") || "0");
};

export const getCurrentCompanieId = () => {
  const currentuser = JSON.parse(
    localStorage.getItem("currentUser") ?? "{}"
  ) as TUser;
  return currentuser?.company_users?.[0]?.company_id;
};

export const isCurrentUserSuperAdmin = () => {
  const currentuser = JSON.parse(
    localStorage.getItem("currentUser") ?? "{}"
  ) as TUser;
  return Number(currentuser?.company_users?.[0]?.company_role) === 1;
};
