import useStyles from "./styles";
import { FC } from "react";
import { Checkbox } from "@mui/material";

type Props = {
  checked?: boolean;
  indeterminate?: boolean;
  onChange?: () => void;
};

export const CustomCheckBox: FC<Props> = ({
  checked,
  onChange,
  indeterminate,
}) => {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.container}
      size={"small"}
      indeterminate={indeterminate}
      checked={checked}
      onChange={onChange}
    />
  );
};
