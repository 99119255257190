import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "./axios";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const departmentsKeys = {
  all: (idCompanie: number) => ["departments", "companie", idCompanie] as const,
  department: () => ["department"] as const,
  departmentByID: (id: number) =>
    [...departmentsKeys.department(), "id", id] as const,
};

export const useGetAllDepartments = (id: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: departmentsKeys.all(id),
    queryFn: async (): Promise<TDepartment[]> => {
      const data = await axios.get(`/companies/${id}/departments`);
      return data?.data;
    },
  });

  return { departments: data ?? [], ...res };
};

export interface RequestDepartment {
  id?: number;
  name: string;
}

export const useCreateDepartment = (
  companyId: number,
  onClose?: () => void,
  initDepartment?: () => void
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newDepartment: RequestDepartment) => {
      const data = await axios.post(
        `/companies/${companyId}/departments`,
        newDepartment
      );
      return data.data?.department;
    },
    onSuccess: (data) => {
      onClose && onClose();
      initDepartment && initDepartment();
      const keys = departmentsKeys.all(companyId);
      const prev = queryClient.getQueryData<TDepartment[]>(keys);
      if (prev && data) {
        queryClient.setQueryData<TDepartment[]>(keys, [...prev, data]);
      }
      toast.success(t("department.createSuccess", "Change saved"));
    },
    onError: (err: any) => {
      if (err?.response?.data?.code === 401) {
        toast.error("Aucune autorisation administrateur de flot");
      } else if (err?.response?.data?.code === "23000") {
        toast.error(
          t("department.departmentAlreadyExists", "Department already exists")
        );
      } else {
        toast.error(err?.response?.data?.message);
      }
    },
  });
};

export const useUpdateDepartment = (
  idCompanie: number,
  idDepartment: number,
  onClose?: () => void,
  initDepartment?: () => void
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (updateDepartment: RequestDepartment) => {
      const data = await axios.put(
        `/companies/${idCompanie}/departments/${idDepartment}`,
        updateDepartment
      );
      return data.data?.department;
    },
    onSuccess: (data) => {
      onClose && onClose();
      initDepartment && initDepartment();
      const keys = departmentsKeys.all(idCompanie);
      const prev = queryClient.getQueryData<TDepartment[]>(keys);
      if (prev && data) {
        const res = prev.map((p) => {
          if (p.id === data.id) {
            return data;
          }
          return p;
        });
        queryClient.setQueryData<TDepartment[]>(keys, res);
      }
      toast.success(t("department.updateSuccess", "Change saved"));
    },
    onError: (err: any) => {
      if (err?.response?.data?.code === "23000") {
        toast.error(
          t("department.departmentAlreadyExists", "Department already exists")
        );
      } else {
        toast.error(err?.response?.data?.message);
      }
    },
  });
};

export interface RequestDeleteDepartment {
  idCompanie: number;
}

export const useDeleteDepartment = (
  idCompanie: number,
  idDepartment: number,
  onClose?: () => void,
  initUserDepartment?: () => void
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (companie: RequestDeleteDepartment) => {
      const data = await axios.delete(
        `/companies/${companie.idCompanie}/departments/${idDepartment}`
      );
      return data.data;
    },
    onSuccess: (_) => {
      onClose && onClose();
      initUserDepartment && initUserDepartment();

      const keys = departmentsKeys.all(idCompanie);
      const prev = queryClient.getQueryData<TDepartment[]>(keys);
      if (prev) {
        const res = prev.filter((department) => department.id !== idDepartment);
        queryClient.setQueryData<TDepartment[]>(keys, res);
      }
      toast.success(t("department.deleteSuccess", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};
