import dayjs from "dayjs";
import { CustomChip } from "../../components/CustomChip/CustomChip";
import {
  getKeyLabelByValue,
  getKeyLabelByValueMintStatus,
  getStatusFromValues,
} from "../../utils/types";
import { ButtonsListVehicle } from "./ButtonsListVehicle";

export const columnsCarsGeneric = [
  { header: "VIN", field: "vin" },
  { header: "Société", field: "companyName" },
  { header: "Immatriculation", field: "immatriculation" },
  { header: "Marque", field: "brand" },
  { header: "Modele", field: "brandModel" },

  {
    header: "Mise en circulation",
    field: "mise_en_circulation",
    render: (rowData: Record<string, any>) => {
      return (
        <div>
          {rowData.mise_en_circulation
            ? dayjs(rowData.mise_en_circulation).format("DD-MM-YYYY")
            : ""}
        </div>
      );
    },
  },
  { header: "Département", field: "companyDepartment" },
  { header: "Nom du collaborateur", field: "lastname" },
  { header: "Prénom du collaborateur", field: "firstname" },
  {
    header: "Statut",
    field: "status",
    render: (rowData: Record<string, any>) => {
      return (
        <CustomChip
          label={
            getKeyLabelByValue(rowData?.fleetVehicleStatut) ?? "Particulier"
          }
          value={getStatusFromValues(rowData?.fleetVehicleStatut)}
        />
      );
    },
  },
  {
    header: "Mint",
    field: "mints",
    render: (rowData: Record<string, any>) => {
      return (
        <>
          {rowData.mintStatus ? (
            <CustomChip
              label={getKeyLabelByValueMintStatus(rowData?.mintStatus) ?? ""}
              value={rowData?.mintStatus?.value}
            />
          ) : (
            <></>
          )}
        </>
      );
    },
  },
  {
    header: "NFT",
    field: "",
    render: (rowData: Record<string, any>) => {
      return (
        <>
          {rowData.mintStatus === "subscribed" ? (
            <ButtonsListVehicle vehicle={rowData as any} />
          ) : (
            <></>
          )}
        </>
      );
    },
  },
];
