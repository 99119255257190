import { createUseStyles } from "react-jss";
type Props = {
  value: "COMMAND" | "AFFECTED" | "FREE" | "ACTIF" | "WAITING";
};

const bgColor = (
  value: "COMMAND" | "AFFECTED" | "FREE" | "ACTIF" | "WAITING"
) => {
  switch (value) {
    case "COMMAND":
      return "#0898FF40";

    case "AFFECTED":
      return "#A5DFA0";

    case "FREE":
      return "#DCD6D6";

    case "ACTIF":
      return "#A5DFA0";

    case "WAITING":
      return "#FEB47F";

    default:
      return "#DCD6D6";
  }
};

const textColor = (
  value: "COMMAND" | "AFFECTED" | "FREE" | "ACTIF" | "WAITING"
) => {
  switch (value) {
    case "COMMAND":
      return "#245C85";

    case "AFFECTED":
      return "#34992B";

    case "FREE":
      return "#504949";

    case "ACTIF":
      return "#34992B";

    case "WAITING":
      return "#9A521F";

    default:
      return "#245C85";
  }
};

const useStyles = createUseStyles({
  container: {
    borderRadius: "6px",
    padding: "3px 8px 3px 8px",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    backgroundColor: (props: Props) => bgColor(props.value),
    width: "fit-content",
    textTransform: "uppercase",
    fontFamily: "Poppins-Regular",
    fontSize: "8px",
    lineHeight: "12px",
    color: (props: Props) => textColor(props.value),
    justifyContent: "center",
    alignItems: "center",
  },
});

export default useStyles;
