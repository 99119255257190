import { CustomTextField } from "../../components/CustomTextField/CustomTextField";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { useState } from "react";
import useStyles from "./styles";
import { colors } from "../../styles/colors";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";

type Props = {};

export const NewPassword: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (fieldName: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  console.log("formData", formData);

  return (
    <div className={classes.container}>
      <div className={classes.divText}>
        <Logo className={classes.logo} />
        <CustomTypography
          variant={"h5"}
          textDecoration={"none"}
          color={colors.WHITE}
          text={t("login.pleaseEnterYourNewPassword")}
          isUppercase
        />
      </div>

      <div className={classes.subContainer}>
        <CustomTextField
          placeholder={t("login.password", "Password")}
          type={"password"}
          pass
          fullWidth={true}
          onChange={(e) => handleInputChange("password", e.target.value)}
        />
        <CustomTextField
          placeholder={t("login.confirmation", "confirmation")}
          type={"password"}
          pass
          fullWidth={true}
          onChange={(e) => handleInputChange("password", e.target.value)}
        />
        <CustomButton
          label={t("login.newPassword", "Validate")}
          height={"40px"}
          // onClick={handleLogin}
          // disabled={isLoading}
          // loading={isLoading}
          variant={"white"}
          width={"fit-content"}
        />
      </div>
    </div>
  );
};
