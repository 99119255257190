import { Cell, Label, Pie, PieChart } from "recharts";
import { CustomBadgeText } from "../../../components/CustomBadgeText/CustomBadgeText";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { IconWithBackground } from "../../../components/IconWithBackground/IconWithBackground";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import useStyles from "./style";
import { FC } from "react";

type props = {
  lightColor: string;
  darkColor: string;
  iconName: string;
  label: string;
  colorGraph?: string;
  isShowGraph?: boolean;
  numberOfUsers: number;
  data?: {
    name: string;
    value: number;
  }[];
  purcent?: string;
};

export const CustomInfos: FC<props> = ({
  lightColor,
  darkColor,
  iconName,
  label,
  colorGraph,
  isShowGraph,
  numberOfUsers,
  data,
  purcent,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const COLORS = ["#F5F5F5", colorGraph ? colorGraph : "#9747FF"];
  return (
    <motion.div
      className={classes.carCard}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.7 }}
    >
      <div className={classes.divCar1}>
        <IconWithBackground
          iconName={iconName}
          backgroundColor={lightColor}
          iconColor={darkColor}
          width={"28px"}
          height={"28px"}
        />
        <CustomBadgeText
          label={label}
          backgroundColor={lightColor}
          colorText={darkColor}
        />
      </div>
      <div className={classes.divCar}>
        <div className={classes.divText2}>
          <CustomTypography text={numberOfUsers.toString()} variant={"h2"} />
          <CustomTypography
            text={t(
              "dashboard.numberOfActiveVehicles",
              "Number of active vehicles"
            )}
            variant={"default"}
          />
        </div>

        <div className={classes.divChart}>
          {isShowGraph && (
            <PieChart width={165} height={165}>
              <Pie
                data={data}
                cx={75}
                cy={75}
                innerRadius={47}
                outerRadius={82}
                paddingAngle={0}
                dataKey="value"
              >
                {data &&
                  data.map((_, index) => (
                    <>
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                      <Label position={"center"}>{purcent}</Label>
                    </>
                  ))}
              </Pie>
            </PieChart>
          )}
        </div>
      </div>
    </motion.div>
  );
};
