import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "./axios";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { companiesKeys } from "./companies";
import { getCurrentCompanie } from "../utils/currentCompanie";

export const userKeys = {
  currentUser: () => ["currentUser"] as const,
  all: (
    page: number,
    per_page: number,
    filter: string,
    company: string,
    role: string | null,
    status: string | null
  ) =>
    [
      "user",
      "page",
      page,
      "per_page",
      per_page,
      "filter",
      filter,
      "company",
      company,
      "role",
      role,
      "status",
      status,
    ] as const,
};

export interface RequestLogin {
  email: string;
  password: string;
}

export const useUserLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return useMutation({
    mutationKey: userKeys.currentUser(),
    mutationFn: (newUser: RequestLogin) => {
      return axios.post("/user/login", JSON.stringify(newUser));
    },
    onSuccess: (data) => {
      const { data: user } = data;
      if (user?.company_users[0]?.company_role === "FLEET_MANAGER") {
        localStorage.setItem("currentUser", JSON.stringify(user));
        localStorage.setItem("token", user?.sessions?.[0]?.token);
        toast.success(t("login.success", "Authentication successful!"));
        window?.location?.reload();
        navigate("/dashboard");
      } else {
        toast.error(t("login.accessDenied", "Access denied"));
      }
    },
    onError: () => {
      toast.error(t("login.error", "Identifier error"));
    },
  });
};

interface ResponseGetUsers {
  data: {
    users: {
      data: TUser[];
      total: number;
    };
  };
}

export const useGetAllUsers = (
  page: number = 1,
  per_page: number = 10,
  filter: string = "",
  company: string = "",
  role: string | null = null,
  status: string | null = null
) => {
  const { data, error, ...res } = useQuery({
    queryKey: userKeys.all(page, per_page, filter, company, role, status),
    queryFn: async (): Promise<ResponseGetUsers> =>
      axios.get(`/admin/users`, {
        params: {
          page,
          per_page,
          searchBar: filter,
          company: company.trim().length > 0 ? company : undefined,
          status: status && status?.trim().length > 0 ? status : undefined,
          role,
        },
      }),
    staleTime: 0,
  });

  if (error) {
  }
  return {
    users: data?.data?.users?.data ?? [],
    totalCount: data?.data?.users?.total,
    ...res,
  };
};

export const useDeleteUserAdmin = (
  onClose?: () => void,
  initUserAdmin?: () => void
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { refetch } = useGetAllUsers();
  return useMutation({
    mutationFn: async (id: number) => {
      const data = await axios.delete(`/admin/users/${id}`);
      return data.data;
    },
    onSuccess: (_) => {
      onClose && onClose();
      initUserAdmin && initUserAdmin();

      refetch();

      // Update cache companie employee
      const companieId = getCurrentCompanie();
      const keysCompanie = companiesKeys.companieEmployee(companieId);
      queryClient.refetchQueries(keysCompanie as any);

      toast.success(t("user.adminDeleteSuccess", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

interface RequestUpdateUserAdmin {
  idUser?: number;
  firstname: string;
  lastname: string;
  email: string;
  is_active: number;
}

export const useUpdateUserAdmin = (
  onClose?: () => void,
  initUserAdmin?: () => void
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { refetch } = useGetAllUsers();
  return useMutation({
    mutationFn: async (newUserAdmin: RequestUpdateUserAdmin) => {
      const data = await axios.put(
        `/admin/users/${newUserAdmin.idUser}?firstname=${newUserAdmin.firstname}&lastname=${newUserAdmin.lastname}&email=${newUserAdmin.email}&is_active=${newUserAdmin.is_active}`
      );
      return data.data;
    },
    onSuccess: () => {
      onClose && onClose();
      initUserAdmin && initUserAdmin();

      refetch();

      // Update cache companie employee
      const companieId = getCurrentCompanie();
      const keysCompanie = companiesKeys.companieEmployee(companieId);
      queryClient.refetchQueries(keysCompanie as any);

      toast.success(t("user.adminUpdateSuccess", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};
