import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

type Props = {
  backgroundColor?: string;
  widthBackground?: string;
  heightBackground?: string;
};

const useStyles = createUseStyles({
  containerIcon: {
    width: (props: Props) =>
      props.widthBackground ? props.widthBackground : "60px",
    height: (props: Props) =>
      props.heightBackground ? props.heightBackground : "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50px",
    backgroundColor: (props: Props) =>
      props.backgroundColor ? props.backgroundColor : colors.default,
  },
});

export default useStyles;
