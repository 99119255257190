import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { dataFilters, generateFakeData } from "./data";
import { Sidebar } from "../../containers/Sidebar/Sidebar";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { CustomButtonTable } from "../../components/CustomButtonTable/CustomButtonTable";
import { CustomTable } from "../../components/CustomTable/CustomTable";
import { colors } from "../../styles/colors";
import { useState } from "react";
import { ActionsTable } from "./ActionsTable/ActionsTable";
import { CustomStatus } from "../../components/CustomStatus/CustomStatus";
import { Checkbox, MenuItem } from "@mui/material";
import { CustomCheckboxSelected } from "../../components/CustomCheckboxSelected/CustomCheckboxSelected";
import { ModalChangeStatus } from "./ModalChangeStatus/ModalChangeStatus";
import { ModalAddService } from "./ModalAddService/ModalAddService";
import { ModalDeleteService } from "./ModalDeleteService/ModalDeleteService";

const columns = [
  { header: "ID", field: "id" },
  { header: "Nom", field: "name" },
  { header: "Description", field: "description" },
];

const data = generateFakeData(10);

type Props = {};

export const Services: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openModalService, setOpenModalService] = useState(false);
  const [openModalDeleteService, setOpenModalDeleteService] = useState(false);
  const [openModalChangeStatus, setOpenModalChangeStatus] = useState(false);
  const [service, setService] = useState<TUserGeneric | null>(null);
  const [filtersSelected, setFiltersSelected] = useState<string[] | []>([]);
  const [dataFiltered, setDataFiltered] = useState(data);

  const onhandleClose = () => {
    setOpenModalService(false);
    setOpenModalDeleteService(false);
    setOpenModalChangeStatus(false);
    setService(null);
  };

  const handleEditService = (service: TUserGeneric) => {
    setOpenModalService(true);
    setService(service);
  };

  const handleDeleteService = (service: TUserGeneric) => {
    setOpenModalDeleteService(true);
    setService(service);
  };

  const handleChangeStatus = (service: TUserGeneric, isActive: boolean) => {
    console.log("isActive", isActive);
    setOpenModalChangeStatus(true);
    setService(service);
  };
  const handleChangeFilters = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFiltersSelected(e.target.value as any);
    const allowedFilterValues = dataFilters.map((filter) => filter.value);
    const selectedFilters = e.target.value as any;

    if (selectedFilters?.length === 0) {
      setDataFiltered(data);
    } else {
      const filteredServices = data.filter((service) => {
        const serviceStatus = service.status.value;

        // Appliquez une logique ET entre les filtres sélectionnés
        return selectedFilters.every(
          (filter: any) =>
            allowedFilterValues.includes(filter) && filter === serviceStatus
        );
      });
      setDataFiltered(filteredServices);
    }
  };

  const actionsColumns = [
    {
      header: "Statut",
      field: "status",
      render: (data: Record<string, any>) => {
        const dataTrue = {
          name: data?.name,
          firstname: data?.firstname,
          email: data?.email,
          status: data?.status?.value === "ACTIF" ? true : false,
        } ;
        const isActive = data?.status?.value === "ACTIF" ? true : false;
        return (
          <CustomStatus
            isActive={isActive}
            onClick={() => handleChangeStatus(dataTrue as any, isActive)}
          />
        );
      },
    },
    {
      header: "",
      field: "",
      render: (data: Record<string, any>) => {
        const dataTrue = {
          name: data?.name,
          firstname: data?.firstname,
          email: data?.email,
          status: data?.status?.value === "ACTIF" ? true : false,
        } ;
        return (
          <ActionsTable
            handleEditUser={() => handleEditService(dataTrue as any)}
            handleDeleteUser={() => handleDeleteService(dataTrue as any)}
          />
        );
      },
    },
  ];

  const columnsWithActions = [...columns, ...actionsColumns];

  return (
    <>
      <ModalAddService
        open={openModalService}
        userInitial={service}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />
      <ModalDeleteService
        open={openModalDeleteService}
        userInitial={service}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />
      <ModalChangeStatus
        open={openModalChangeStatus}
        userInitial={service}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />
      <Sidebar
        styles={{
          sidebarRightRoot: classes.vehiculeRoot,
          sidebarRoot: classes.contentMain,
        }}
      >
        <div className={classes.containerHeader}>
          <CustomTypography
            variant="h2"
            text={t("services.serviceManagment", "Service managment")}
            isUppercase
          />
          <CustomButtonTable
            variant={"secondary"}
            textVariant={"link"}
            label={t("services.addAService", "Add a service")}
            width={"fit-content"}
            fontWeight={"bold"}
            textDecoration={"none"}
            iconName={"plus"}
            padding={"0px 15px"}
            textTransform="uppercase"
            onClick={() => setOpenModalService(true)}
          />
        </div>
        <div className={classes.divApercu}>
          <div className={classes.divApercuFilter}>
            <div className={classes.divFilter}>
              <CustomTypography
                text={`${t("admin.filterByStatus", "Filter by status")} :`}
                variant={"link"}
                fontWeight={"bold"}
                color={colors.darkblack}
              />
              <CustomCheckboxSelected
                onChange={handleChangeFilters}
                value={filtersSelected}
                SelectProps={{
                  multiple: true,
                  renderValue: (selecteds: any): string => {
                    return selecteds
                      ?.map(
                        (selected: string) =>
                          dataFilters.find((item) => item.value === selected)
                            ?.name ?? ""
                      )
                      ?.join(", ");
                  },
                }}
              >
                {dataFilters.map((p) => (
                  <MenuItem key={p.id} value={p.value}>
                    <Checkbox
                      color="primary"
                      checked={filtersSelected?.some(
                        (filterSelected) => filterSelected === p.value
                      )}
                    />
                    {p.name}
                  </MenuItem>
                ))}
              </CustomCheckboxSelected>
            </div>
          </div>
        </div>
        <CustomTable
          columns={columnsWithActions}
          data={dataFiltered}
          isSearchable
          showSelectedField
          tableMaxHeight={"520px"}
          titleHeader={t("services.servicesLists", "Services lists")}
        />
      </Sidebar>
    </>
  );
};
