import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles({
  divText: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    "& div:first-child": {
      padding: "0px 14px",
    },
  },
  divTextDisplay: {
    width: "100%",
    height: "40px",
    borderRadius: "50px",
    border: `2px solid ${colors.WHITE}`,
    backgroundColor: colors.default,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > svg": { paddingRight: "13px" },
  },
});

export default useStyles;
