import Dialog from "@mui/material/Dialog";
import useStyles from "./styles";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import { colors } from "../../styles/colors";
import { CustomIconButton } from "../CustomIconButton/CustomIconButton";

type Props = {
  width?: string | number;
  maxWidth?: string | number;
  minWidth?: string | number;
  children: React.ReactNode;
  title: string;
  open: boolean;
  onClose?: () => void;
};

export const CustomModalInfo: React.FC<Props> = ({
  width,
  maxWidth,
  minWidth,
  children,
  title,
  open,
  onClose,
}) => {
  const classes = useStyles({ width, maxWidth, minWidth });

  return (
    <Dialog open={open} className={classes.containerDialog}>
      <div className={classes.titleContainer}>
        <CustomTypography
          text={title}
          variant={"h5"}
          color={colors.secondary}
          isUppercase
        />
        <CustomIconButton
          color={colors.BLACK}
          backgroundColor={colors.default}
          widthIcon={"25px"}
          heightIcon={"25px"}
          iconName="close"
          onClick={onClose}
        />
      </div>
      {children}
    </Dialog>
  );
};
