import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

type PropsStyle = {
  backgroundColor?: string;
};

const useStyles = createUseStyles({
  divText: {
    height: "17px",
    borderRadius: "6px",
    padding: "3px 8px 3px 8px",
    backgroundColor: (props: PropsStyle) =>
      props.backgroundColor ? props.backgroundColor : colors.lightpurple,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > div": {
      fontSize: "8px",
    },
  },
});

export default useStyles;
