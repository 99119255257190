import { colors } from "../../styles/colors";

import { createUseStyles } from "react-jss";
const useStyles = createUseStyles((_) => ({
  textfield: {
    "& .MuiInputBase-input": {
      fontSize: 13,
      fontWeight: "normal",
      fontFamily: "Poppins-Regular",
      color: colors.WHITE,
      padding: "11px 25px",
    },
    "& .MuiInputBase-root": {
      border: `2px solid ${colors.WHITE}`,
      borderRadius: "50px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
}));
export default useStyles;
