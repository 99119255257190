import useStyles from "./styles";
import { MouseEventHandler } from "react";
import { Button } from "@mui/material";
import classNames from "classnames";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import { Icon } from "../Icon/Icon";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  label: string;
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "white"
    | "default"
    | "withIcon";
  textVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "link" | "default";
  disabled?: boolean | undefined;
  styles?: any;
  width?: string | number;
  padding?: string | number;
  marginBottom?: string | number;
  fontWeight?: string;
  textDecoration?: string;
  iconName?: string;
  widthIcon?: string | number;
  heightIcon?: string | number;
  textTransform?: string;
  endIcon?: boolean;
};

export const CustomButtonTable: React.FC<Props> = ({
  onClick,
  label,
  variant = "default",
  textVariant = "link",
  disabled,
  styles,
  width,
  padding,
  marginBottom,
  fontWeight,
  textDecoration,
  iconName,
  widthIcon = 15,
  heightIcon = 15,
  textTransform,
  endIcon = false,
}) => {
  const classes = useStyles({
    width,
    padding,
    marginBottom,
    textTransform,
    endIcon,
  });

  let variantClasses;
  switch (variant) {
    case "default":
      variantClasses = classes.variantDefault;
      break;
    case "primary":
      variantClasses = classes.variantPrimary;
      break;
    case "secondary":
      variantClasses = classes.variantSecondary;
      break;
    case "tertiary":
      variantClasses = classes.variantTertiary;
      break;
    case "white":
      variantClasses = classes.variantWhite;
      break;
    case "withIcon":
      variantClasses = classes.variantWithIcon;
      break;
    default:
      break;
  }

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        classes.buttonRoot,
        variantClasses,
        styles?.buttonRoot
      )}
    >
      {!endIcon && iconName && (
        <Icon name={iconName} width={widthIcon} height={heightIcon} />
      )}
      <CustomTypography
        text={label}
        variant={textVariant}
        fontWeight={fontWeight}
        textDecoration={textDecoration}
      />
      {endIcon && iconName && (
        <Icon name={iconName} width={widthIcon} height={heightIcon} />
      )}
    </Button>
  );
};
