import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { CustomTextField } from "../../components/CustomTextField/CustomTextField";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { useState } from "react";
import useStyles from "./styles";
import { colors } from "../../styles/colors";
import { useUserLogin } from "../../api/user";

type Props = {};

export const Login: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { mutate: login, isPending: isLoading } = useUserLogin();

  const handleInputChange = (fieldName: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleLogin = () => {
    login({ email: formData.email, password: formData.password });
  };

  return (
    <div className={classes.container}>
      <Logo className={classes.logo} />
      <div className={classes.subContainer}>
        <CustomTextField
          placeholder={t("login.email", "Email")}
          fullWidth={true}
          onChange={(e) => handleInputChange("email", e.target.value)}
        />
        <CustomTextField
          placeholder={t("login.password", "Password")}
          type={"password"}
          pass
          fullWidth={true}
          onChange={(e) => handleInputChange("password", e.target.value)}
        />
        <CustomButton
          label={t("login.login", "Login")}
          height={"40px"}
          onClick={handleLogin}
          disabled={isLoading}
          loading={isLoading}
          variant={"white"}
          width={"fit-content"}
        />
        <CustomTypography
          variant={"link"}
          textDecoration={"none"}
          color={colors.WHITE}
          styles={{ containerText: classes.forgotPassword }}
          text={t("login.forgotPassword", "Forgot password")}
        />
      </div>
    </div>
  );
};
