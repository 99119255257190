import { FC } from "react";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import useStyles from "./styles";

type props = {
  isActive: boolean;
};

export const CustomLoader: FC<props> = ({ isActive }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      {isActive && (
        <div className={classes.container}>
          <div>
            <LoadingOverlay
              active={isActive}
              spinner
              text={t("common.loading", "Loading...")}
            />
          </div>
        </div>
      )}
    </>
  );
};
