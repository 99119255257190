import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  carCard: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
    padding: "30px",
    backgroundColor: colors.WHITE,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.09)",
    justifyContent: "space-between",
  },
  divCar1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  divCar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  divText2: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  divChart: {
    "& .recharts-wrapper": {
      width: "93px !important",
      height: "90px !important",
    },
    "& .recharts-text": {
      fontFamily: "Poppins-Regular",
      fontSize: "25px",
      fill: colors.darkblack,
    },
  },
}));

export default useStyles;
