import { FC } from "react";
import { CustomTypography } from "../CustomTypography/CustomTypography";

type Props = {
  label: string;
  value: string;
  isUppercaseValue?: boolean;
};

export const CustomTypographyLabelValue: FC<Props> = ({
  label,
  value,
  isUppercaseValue = true,
}) => {
  return (
    <div>
      <CustomTypography text={label} variant="link" lineHeight="9px" />
      <CustomTypography
        text={value}
        variant="default"
        isUppercase={isUppercaseValue}
        fontWeight="bold"
      />
    </div>
  );
};
