import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles({
  contentMain: {
    overflow: "hidden",
    height: "100vh",
  },
  containerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "15px 0px 15px 0px",
  },
  vehiculeRoot: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "30px 30px",
    overflowY: "auto",
    height: "calc(100vh - 60px)",
    backgroundColor: colors.bgcolor,
  },

  divApercu: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  divApercuFilter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divApercu2: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    flexWrap: "wrap",
  },

  divFilter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "256px",
    textWrap: "nowrap",
    gap: "10px",
    "& .MuiTextField-root": {
      minWidth: "inherit",
    },
  },

  divAway: {
    position: "absolute",
    top: 35,
    right: 0,
    left: 0,
    zIndex: 10,
    width: "160px",
    borderRadius: "10px",
    padding: "5px",
    backgroundColor: colors.WHITE,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
  },
  divAwayCustom: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    borderRadius: "3px",
    padding: "2px 5px 2px 5px",
    gap: "8px",
  },
  divIconArrow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
  },
});

export default useStyles;
