import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles((_) => ({
  contentMain: {
    overflow: "hidden",
    height: "100vh",
  },
  vehiculeRoot: {
    overflowY: "auto",
    padding: "20px 30px",
    height: "calc(100vh - 40px)",
    backgroundColor: colors.bgcolor,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "22px",
  },
  containerHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 1315,
    width: "100%",
  },
  containerBody: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  containerCarsInfos: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    "& > div": {
      flex: 1,
    },
  },
  divScroll: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    overflowX: "auto",
    padding: "3px",
  },
}));

export default useStyles;
