import { useTranslation } from "react-i18next";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import { Icon } from "../Icon/Icon";
import useStyles from "./styles";
import { FC } from "react";
import { useDropzone } from "react-dropzone";

type props = {
  fileName?: string;
  onDrop: (acceptedFiles: File[]) => void;
};

export const CustomLoadFileInput: FC<props> = ({ fileName, onDrop }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
    multiple: false,
    maxSize: 10 * 1024 * 1024, // 10 megabytes in bytes
  });

  return (
    <div className={classes.divText} {...getRootProps()}>
      <input {...getInputProps()} />
      <div>
        <CustomTypography
          text={`${t("common.loadCSV", "Load a .csv file")} *`}
          variant="link"
        />
      </div>
      <div className={classes.divTextDisplay}>
        <CustomTypography text={fileName ?? ""} variant={"default"} />
        <Icon name={"paperClip"} width={"20px"} height={"20px"} />
      </div>
    </div>
  );
};
