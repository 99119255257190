import { createUseStyles } from "react-jss";

type Props = {
  width?: string | number;
};

const useStyles = createUseStyles(() => ({
  containerDialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      width: (props: Props) => (props.width ? props.width : "383px"),
      borderRadius: "15px",
      padding: "20px",
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
  },
}));

export default useStyles;
