import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles({
  contentMain: {
    overflow: "hidden",
    height: "100vh",
  },
  containerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "15px 0px 15px 0px",
  },
  vehiculeRoot: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "30px 30px",
    overflowY: "auto",
    height: "calc(100vh - 60px)",
    backgroundColor: colors.bgcolor,
  },

  divApercu: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  divApercuFilter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divApercu2: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },

  divFilter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "256px",
    textWrap: "nowrap",
    gap: "10px",
  },

  divIconArrow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
  },
});

export default useStyles;
