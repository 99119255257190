import { FC } from "react";
import { Icon } from "../Icon/Icon";
import { colors } from "../../styles/colors";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import i18n from "../../i18n/i18n";
import useStyles from "./styles";

type props = {
  isActive: boolean;
  onClick?: () => void;
};

export const CustomStatus: FC<props> = ({ isActive, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.container} onClick={onClick}>
      {isActive ? (
        <>
          <Icon
            name={"toggleLeft"}
            width={"24px"}
            height={"24px"}
            color={colors.success}
          />
          <CustomTypography
            text={i18n.t("common.actived", "Actived")}
            variant={"link"}
            fontWeight={"bold"}
            color={colors.success}
          />
        </>
      ) : (
        <>
          <Icon
            name={"toggleRight"}
            width={"24px"}
            height={"24px"}
            color={colors.secondary}
          />
          <CustomTypography
            text={i18n.t("common.deactived", "Deactived")}
            variant={"link"}
            fontWeight={"bold"}
            color={colors.secondary}
          />
        </>
      )}
    </div>
  );
};
