import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import useStyles from "./styles";
import { FC } from "react";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";

type props = {
  open: boolean;
  userGenericInitial: TUserGeneric;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalSendEmailUserGeneric: FC<props> = ({
  open,
  userGenericInitial,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={""}
      onClose={onClose}
      isConfirmation
      textConfirmation={t("common.resend", "Resend")}
      onConfirm={() => {}}
    >
      <div className={classes.textModalContainer}>
        <CustomTypography
          text={t("userGeneric.sendEmail", {
            name: userGenericInitial?.lastname,
            firstname: userGenericInitial?.firstname,
          })}
          variant="default"
        />
      </div>
    </CustomModal>
  );
};
