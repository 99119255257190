import { Route, Routes as RoutesDOM } from "react-router-dom";
import { Login } from "../pages/Login/Login";
import PrivateRoute from "./PrivateRoute";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { NewPassword } from "../pages/NewPassword/NewPassword";
import { Compagnies } from "../pages/Compagnies/Compagnies";
import { Services } from "../pages/Services/Services";
import { Department } from "../pages/Department/Department";
import Retranscription from "../pages/Retranscription/Retranscription";
import { CarsGeneric } from "../pages/CarsGeneric/CarsGeneric";
import { UsersGeneric } from "../pages/UsersGeneric/ UsersGeneric";

export const Routes = () => {
  const isAuthentificated = !!localStorage.getItem("token");
  return (
    <RoutesDOM>
      <Route
        path="/"
        element={
          isAuthentificated ? (
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          ) : (
            <Login />
          )
        }
      />
      <Route path="/new_password" element={<NewPassword />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <PrivateRoute>
            <UsersGeneric />
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <UsersGeneric />
          </PrivateRoute>
        }
      />
      <Route
        path="/cars"
        element={
          <PrivateRoute>
            <CarsGeneric />
          </PrivateRoute>
        }
      />
      <Route
        path="/services"
        element={
          <PrivateRoute>
            <Services />
          </PrivateRoute>
        }
      />
      <Route path="/compagnies">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Compagnies />
            </PrivateRoute>
          }
        />
        <Route
          path="users/:id"
          element={
            <PrivateRoute>
              <UsersGeneric />
            </PrivateRoute>
          }
        />
        <Route
          path="vehicules/:id"
          element={
            <PrivateRoute>
              <CarsGeneric />
            </PrivateRoute>
          }
        />
        <Route
          path=":id/departments"
          element={
            <PrivateRoute>
              <Department />
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path="/retranscription"
        element={
          <PrivateRoute>
            <Retranscription />
          </PrivateRoute>
        }
      />
    </RoutesDOM>
  );
};
