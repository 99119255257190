import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import useStyles from "./styles";
import { FC } from "react";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { useDeleteUserAdmin as useDelete } from "../../../api/user";

type props = {
  open: boolean;
  userGenericInitial: TUserGeneric;
  onCancel?: () => void;
  onClose?: () => void;
  initUserGeneric?: () => void;
};

export const ModalDeleteUserGeneric: FC<props> = ({
  open,
  userGenericInitial,
  onCancel,
  onClose,
  initUserGeneric,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { mutate: deleteUserGeneric, isPending } = useDelete(
    onClose,
    initUserGeneric
  );

  const onConfirm = () => {
    deleteUserGeneric(userGenericInitial.userID ?? -1);
  };

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={""}
      onClose={onClose}
      isConfirmation
      textConfirmation={t("login.newPassword", "Validate")}
      loadingConfirmation={isPending}
      onConfirm={onConfirm}
    >
      <div className={classes.textModalContainer}>
        <CustomTypography
          text={t("userGeneric.delete", {
            name: userGenericInitial?.lastname,
            firstname: userGenericInitial?.firstname,
          })}
          variant="default"
        />
      </div>
    </CustomModal>
  );
};
