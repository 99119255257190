import { FC } from "react";
import { Icon } from "../../../components/Icon/Icon";
import { colors } from "../../../styles/colors";
import useStyles from "./styles";

type props = {
  handleEditUserGeneric?: () => void;
  handleSeeUserGeneric?: () => void;
  handleConnectUserGeneric?: () => void;
  handleSendEmailUserGeneric?: () => void;
  handleDeleteUserGeneric?: () => void;
};

export const ActionsTable: FC<props> = ({
  handleEditUserGeneric,
  handleSeeUserGeneric,
  // handleConnectUserGeneric,
  // handleSendEmailUserGeneric,
  handleDeleteUserGeneric,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.divContainer}>
      <Icon
        name="eye"
        color={colors.darkblack}
        height={"24px"}
        width={"24px"}
        style={{ cursor: "pointer" }}
        onClick={handleSeeUserGeneric}
      />
      {/* <Icon
        name="login"
        color={colors.darkblack}
        height={"24px"}
        width={"24px"}
        onClick={handleConnectUserGeneric}
        style={{ cursor: "pointer" }}
      />
      <Icon
        name="reload"
        color={colors.darkblack}
        height={"24px"}
        width={"24px"}
        onClick={handleSendEmailUserGeneric}
        style={{ cursor: "pointer" }}
      /> */}
      <Icon
        name="pencil"
        color={colors.darkblack}
        height={"24px"}
        width={"24px"}
        style={{ cursor: "pointer" }}
        onClick={handleEditUserGeneric}
      />
      <Icon
        name="trash"
        color={colors.red}
        height={"24px"}
        width={"24px"}
        onClick={handleDeleteUserGeneric}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};
