import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "10px 15px 20px 15px",
  },
  divContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& > div": {
      flex: 1,
    },
  },
  textModalContainer: {
    padding: "10px 15px 20px 15px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  customSelect: {
    "& > div": {
      margin: "0px",
    },
  },
  customRadio: {
    "& .MuiFormGroup-root": {
      "& label:first-child": {
        marginBottom: "7px",
      },
    },
    "& .MuiRadio-root": {
      padding: "0px 9px",
    },
    "& .Mui-checked": {
      color: colors.secondary,
    },
    "& .MuiTypography-root": {
      fontFamily: "Poppins-Regular",
      fontSize: "13px",
      paddingTop: "2px",
    },
  },
}));

export default useStyles;
