import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

type Props = {
  backgroundColor?: string;
};

const useStyles = createUseStyles({
  divCustom: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 13px 10px 13px",
    borderRadius: "15px",
    backgroundColor: colors.WHITE,
  },
  divCustomIcon: {
    width: "25px",
    height: "25px",
    borderRadius: "7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    backgroundColor: (props: Props) =>
      props.backgroundColor ? props.backgroundColor : colors.WHITE,
  },
});

export default useStyles;
