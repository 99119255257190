import { NO_FILTER } from "../../utils/utils";

export const dataFiltersStatusCarsGeneric = [
  // {
  //   id: "1",
  //   name: "N'est assigné à aucune société",
  //   value: "NO_SOCIETY",
  // },
  {
    id: "2",
    name: "En commande",
    value: "O",
  },
  {
    id: "3",
    name: "Affecté",
    value: "A",
  },
  {
    id: "4",
    name: "Libre",
    value: "F",
  },
  {
    id: "5",
    name: "En attente",
    value: "W",
  },
  {
    id: "6",
    name: NO_FILTER,
    value: "",
  },
];

export const removeFilterCompany = {
  id: -1,
  name: NO_FILTER,
  registration_id: "",
  logo: "",
  is_fleet: -1,
  is_rental: -1,
} as TCompanie;
