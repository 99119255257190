import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../../containers/Sidebar/Sidebar";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { CustomButtonTable } from "../../components/CustomButtonTable/CustomButtonTable";
import { CustomTable } from "../../components/CustomTable/CustomTable";
import { useState } from "react";
import { ActionsTable } from "./ActionsTable/ActionsTable";
import { ModalAddDepartment } from "./ModalAddDepartment/ModalAddDepartment";
import { ModalDeleteDepartment } from "./ModalDeleteDepartment/ModalDeleteDepartment";
import { Icon } from "../../components/Icon/Icon";
import { useNavigate, useParams } from "react-router-dom";
import { setCurrentCompanie } from "../../utils/currentCompanie";
import { useGetAllDepartments } from "../../api/departments";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";

const columns = [
  { header: "ID", field: "id" },
  { header: "Nom", field: "name" },
];

type Props = {};

export const Department: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initDepartment = {
    id: -1,
    name: "",
  };

  const navigate = useNavigate();
  const { id = "" } = useParams();
  const companieId = parseInt(id);
  setCurrentCompanie(companieId);

  const { departments, isPending } = useGetAllDepartments(companieId);

  const [openModalDepartment, setOpenModalDepartment] = useState(false);
  const [openModalDeleteDepartment, setOpenModalDeleteDepartment] =
    useState(false);
  const [department, setDepartment] = useState<TDepartment>(initDepartment);

  const onhandleClose = () => {
    setOpenModalDepartment(false);
    setOpenModalDeleteDepartment(false);
    setDepartment(initDepartment);
  };

  const handleEditDepartment = (department: TDepartment) => {
    setOpenModalDepartment(true);
    setDepartment(department);
  };

  const handleDeleteDepartment = (department: TDepartment) => {
    setOpenModalDeleteDepartment(true);
    setDepartment(department);
  };

  const actionsColumns = [
    {
      header: "",
      field: "",
      render: (data: Record<string, any>) => {
        const dataTrue = {
          id: data.id,
          name: data?.name,
        } as TDepartment;
        return (
          <ActionsTable
            handleEditDepartment={() => handleEditDepartment(dataTrue)}
            handleDeleteDepartment={() => handleDeleteDepartment(dataTrue)}
          />
        );
      },
    },
  ];

  const columnsWithActions = [...columns, ...actionsColumns];

  return (
    <>
      {isPending && <CustomLoader isActive={isPending} />}
      <ModalAddDepartment
        open={openModalDepartment}
        companyId={companieId}
        departmentInitial={department}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />
      <ModalDeleteDepartment
        open={openModalDeleteDepartment}
        companyId={companieId}
        departmentInitial={department}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />
      <Sidebar
        styles={{
          sidebarRightRoot: classes.vehiculeRoot,
          sidebarRoot: classes.contentMain,
        }}
      >
        <div className={classes.containerHeader}>
          <div className={classes.divIconArrow}>
            <Icon
              style={{ cursor: "pointer" }}
              name={"arrowLeft"}
              width={"28px"}
              height={"28px"}
              onClick={() => navigate(-1)}
            />
            <CustomTypography
              variant="h2"
              text={t("department.departmentManagment", "Department managment")}
              isUppercase
            />
          </div>

          <CustomButtonTable
            variant={"secondary"}
            textVariant={"link"}
            label={t("department.addDepartment", "Add department")}
            width={"fit-content"}
            fontWeight={"bold"}
            textDecoration={"none"}
            iconName={"plus"}
            padding={"0px 15px"}
            textTransform="uppercase"
            onClick={() => setOpenModalDepartment(true)}
          />
        </div>
        <CustomTable
          columns={columnsWithActions}
          data={departments}
          isSearchable
          showSelectedField
          tableMaxHeight={"520px"}
          titleHeader={t("department.listOfDepartments", "List of departments")}
        />
      </Sidebar>
    </>
  );
};
