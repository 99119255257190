import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import useStyles from "./styles";
import { ReactNode } from "react";

type Props = {
  placeholder?: string;
  label?: string;
  options?: any[];
  marginBottom?: string | number;
  name?: string;
  value?: any;
  onChange?: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
};

// NB Preciser value dans le composant parent si options est un object

export const CustomTextSelect: React.FC<Props> = ({
  placeholder,
  label,
  options,
  marginBottom,
  name,
  value,
  onChange,
}) => {
  const classes = useStyles({ marginBottom });

  return (
    <div className={classes.container}>
      {label && (
        <div className={classes.containerLabel}>
          <CustomTypography
            text={label}
            variant={"link"}
            textDecoration={"none"}
          />
        </div>
      )}
      <FormControl className={classes.formControl} variant="outlined">
        <Select
          name={name}
          label={placeholder}
          placeholder={placeholder}
          onChange={onChange}
          value={value ? (typeof value === "string" ? value : value.label) : ""}
        >
          {options &&
            options.map((option, index) => (
              <MenuItem key={index} value={option}>
                {typeof option === "string" ? option : option.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};
