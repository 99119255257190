import useStyles from "./styles";
import { FC } from "react";

type Props = {
  label: string;
  value: "COMMAND" | "AFFECTED" | "FREE" | "ACTIF" | "WAITING";
};

export const CustomChip: FC<Props> = ({ label, value }) => {
  const classes = useStyles({ value });

  return <div className={classes.container}>{label}</div>;
};
