import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles({
  contentMain: {
    overflow: "hidden",
    height: "100vh",
  },
  containerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "15px 0px 15px 0px",
  },
  vehiculeRoot: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "30px 30px",
    overflowY: "auto",
    height: "calc(100vh - 60px)",
    backgroundColor: colors.bgcolor,
  },

  divApercu: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  divApercuFilter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divApercu2: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  },
  containerCompagnies: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "10px",
  },
  divTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  divCompagnies: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    flexWrap: "wrap",
  },

  divSociety: {
    borderRadius: "10px",
    paddingTop: "17px",
    paddingBottom: "17px",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "flex",
    flexDirection: "row",
    gap: "25px",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.WHITE,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
  },
  divText: { display: "flex", flexDirection: "column", gap: "15px" },
  divIcon: {
    cursor: "pointer",
  },
});

export default useStyles;
