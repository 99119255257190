import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles({
  formControl: {
    "&.MuiFormControl-root": {
      "& input": {
        padding: "8px",
        paddingLeft: "16px",
        "&::placeholder": {
          color: colors.darkblack,
          fontFamily: "Poppins-Bold",
          fontSize: "10px",
        },
      },
      "& fieldset": {
        border: "none",
      },
      "& .MuiInputBase-root.MuiOutlinedInput-root": {
        fontFamily: "Poppins-Regular",
        backgroundColor: colors.default,
        borderRadius: "50px",
        fontSize: "11px",
      },
    },
  },
});

export default useStyles;
