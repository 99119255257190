import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  divContainerFleet: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    "& > div": {
      flex: 1,
    },
  },
  divFleet: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "15px",
    padding: "30px",
    backgroundColor: colors.WHITE,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.09)",
    "& > div": {
      flex: 1,
    },
  },
  divUsers: {
    display: "flex",
    flexDirection: "column",
    gap: "35px",
  },
  divChiffre: { display: "flex", flexDirection: "column", gap: "15px" },
  divRecharts: {
    display: "flex",
    alignItems: "flex-end",
  },
}));

export default useStyles;
