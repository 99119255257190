import { SelectProps, TextField } from "@mui/material";
import { ChangeEventHandler, FC } from "react";
import useStyles from "./styles";

type props = {
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  SelectProps?: Partial<SelectProps<unknown>>;
  value?: unknown;
  children?: React.ReactNode;
};

export const CustomCheckboxSelected: FC<props> = ({
  onChange,
  SelectProps,
  value,
  children,
}) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      fullWidth
      InputLabelProps={{ shrink: false }}
      select
      className={classes.textfield}
      SelectProps={{
        ...SelectProps,
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        },
      }}
      value={value}
      onChange={onChange}
    >
      {children}
    </TextField>
  );
};
