import useStyles from "./styles";
import { FC, useState } from "react";
import { Icon } from "../Icon/Icon";
import { CustomTypography } from "../CustomTypography/CustomTypography";

type Props = {
  text: string;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
};

export const CustomCheckboxSquare: FC<Props> = ({ onChange, text ,defaultChecked }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState<boolean>(defaultChecked || false);

  const onClick = () => {
    setChecked(!checked);
    onChange && onChange(!checked);
  };

  return (
    <div className={classes.checkbox} onClick={onClick}>
      {checked ? (
        <Icon name="squareCheck" width={"12px"} height={"12px"} />
      ) : (
        <Icon name="square" width={"12px"} height={"12px"} />
      )}
      <CustomTypography variant="link" text={text} />
    </div>
  );
};
