import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

type Props = {
  marginBottom?: string | number;
  rows?: string | number;
};

const useStyles = createUseStyles((_) => ({
  container: {
    marginBottom: (props: Props) =>
      props.marginBottom ? props.marginBottom : "15px",
  },
  formControl: {
    "&.MuiFormControl-root": {
      width: "100%",
      marginTop: "5px",
      "& input": {
        padding: "10px",
        paddingLeft: "25px",
      },
      "& fieldset": {
        border: "none",
      },
      "& .MuiInputBase-root.MuiOutlinedInput-root": {
        fontFamily: "Poppins-Regular",
        backgroundColor: colors.default,
        border: `2px solid ${colors.WHITE}`,
        borderRadius: (props: Props) => (props.rows ? "10px" : "50px"),
        fontSize: "13px",
        color: colors.BLACK,
      },
    },
  },
  containerLabel: {
    paddingLeft: "26px",
  },
}));
export default useStyles;
