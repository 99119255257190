import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import toast from "react-hot-toast";
import useStyles from "./styles";
import { FC, useEffect, useState } from "react";
import { CustomTypographyLabelValue } from "../../../components/CustomTypographyLabelValue/CustomTypographyLabelValue";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { CustomStatus } from "../../../components/CustomStatus/CustomStatus";
import { motion } from "framer-motion";

type props = {
  open: boolean;
  userGenericInitial: TUserGeneric;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalSeeUserGeneric: FC<props> = ({
  open,
  userGenericInitial,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [userGeneric, setUserGeneric] =
    useState<TUserGeneric>(userGenericInitial);

  useEffect(() => {
    if (userGenericInitial) {
      setUserGeneric(userGenericInitial);
    } else {
      setUserGeneric({
        id: -1,
        lastname: "",
        firstname: "",
        email: "",
        is_active: 1,
      });
    }
  }, [userGenericInitial]);

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={`${userGeneric?.firstname} ${userGeneric?.lastname}`}
      onClose={onClose}
      onSave={() => toast.success(t("common.changeSaved", "Change saved"))}
      width={"663px"}
      showButtonAction={false}
    >
      <motion.div
        className={classes.container}
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 20 }}
        transition={{ duration: 1 }}
      >
        <div className={classes.divContainer}>
          <CustomTypographyLabelValue
            label={t("user.name", "Name")}
            value={userGeneric?.lastname ?? ""}
          />
          <CustomTypographyLabelValue
            label={t("user.firstname", "Firstname")}
            value={userGeneric?.firstname ?? ""}
          />
        </div>
        <div className={classes.divContainer}>
          <CustomTypographyLabelValue
            label={t("user.email", "Email")}
            value={userGeneric?.email ?? ""}
            isUppercaseValue={false}
          />
          <div>
            <CustomTypography
              text={t("user.status", "Status")}
              variant="link"
              lineHeight="9px"
            />
            <CustomStatus
              isActive={userGeneric?.is_active === 1 ? true : false}
            />
          </div>
        </div>
      </motion.div>
    </CustomModal>
  );
};
