import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles((_) => ({
  textfield: {
    "& .MuiFormLabel-root": {
      fontFamily: "Poppins-Regular",
      fontSize: 12,
      lineHeight: "0px",
      overflow: "inherit",
    },
    "& .MuiInputBase-root": {
      backgroundColor: colors.default,
      borderRadius: "50px",
      "& > input": {
        opacity: 0,
        border: "none",
        backgroundColor: "transparent",
        fontSize: "12px",
        paddingLeft: "14px",
        fontFamily: "Poppins-Regular",
        paddingBottom: "8px",
        "&::placeholder": {
          color: "#000000",
        },
      },
    },
    "& .MuiSelect-select": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "& .MuiInputBase-input": {
      fontSize: 12,
      fontWeight: "normal",
      fontFamily: "Poppins-Regular",
      paddingLeft: "12px",
      color: colors.darkblack,
    },
  },
}));

export default useStyles;
