import i18n from "../../i18n/i18n";

export const dataFiltersUsersGeneric = [
  {
    id: "1",
    name: i18n.t("common.actived", "Actived"),
    value: "1",
  },
  {
    id: "2",
    name: i18n.t("common.deactived", "Deactived"),
    value: "0",
  },
  {
    id: "2",
    name: "Pas de filtre",
    value: "",
  },
];
