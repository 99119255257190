import { colors } from "../../styles/colors";
import { createUseStyles } from "react-jss";

type PropsStyle = {
  fontWeight?: string;
  textDecoration?: string;
  textAlign?: string;
  lineHeight?: string;
  color?: string;
};

const baseStyles = () => ({
  textAlign: (props: PropsStyle) => props.textAlign || undefined,
  lineHeight: (props: PropsStyle) => props.lineHeight || undefined,
  fontWeight: (props: PropsStyle) => props.fontWeight || undefined,
  textDecoration: (props: PropsStyle) => props.textDecoration || undefined,
  color: (props: PropsStyle) => props.color || undefined,
});

const useStyles = createUseStyles((_) => ({
  uppercase: {
    textTransform: "uppercase",
    ...baseStyles(),
  },
  h1: {
    fontSize: 40,
    fontFamily: "Poppins-Black",
    ...baseStyles(),
  },
  h2: {
    fontSize: 30,
    fontFamily: "Poppins-Black",
    ...baseStyles(),
    lineHeight: (props: PropsStyle) => props.lineHeight || "24px",
  },
  h3: {
    fontSize: 20,
    fontFamily: "Poppins-Black",
    ...baseStyles(),
  },
  h4: {
    fontSize: 15,
    fontFamily: "Poppins-Bold",
    ...baseStyles(),
  },
  h5: {
    fontSize: 13,
    fontFamily: "Poppins-Bold",
    ...baseStyles(),
  },
  default: {
    fontSize: 13,
    fontFamily: "Poppins-Regular",
    ...baseStyles(),
  },
  link: {
    fontSize: 10,
    fontFamily: "Poppins-Regular",
    ...baseStyles(),
    "& a": {
      color: (props: PropsStyle) => props.color || colors.BLACK,
    },
  },
}));

export default useStyles;
