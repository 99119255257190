// firebaseConfig.ts
import firebase from "firebase/app";
import "firebase/storage";

const storageName = "hardy-notch-testing";

const firebaseConfig = {
  apiKey: "AIzaSyDmw_AeAy6SH5JiQRXswO36vpPKj5G8VUY",
  authDomain: "<your-auth-domain>",
  databaseURL: "<your-database-url>",
  storageBucket: "gs://hardy-notch-testing",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };

export function uriToBlob(uri: any) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
      // return the blob
      resolve(xhr.response);
    };

    xhr.onerror = function () {
      // something went wrong
      reject(new Error("uriToBlob failed"));
    };

    // this helps us get a blob
    xhr.responseType = "blob";

    xhr.open("GET", uri, true);
    xhr.send(null);
  });
}

export const uploadImage = async (image: any, contentType = "image/jpeg") => {
  const uploadToFirebase = (blob: any) => {
    return new Promise((resolve, reject) => {
      var storageRef = storage.ref();
      storageRef
        .child(`${new Date().getTime()}.jpg`)
        .put(blob, {
          contentType: contentType,
        })
        .then((snapshot) => {
          resolve(snapshot);
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  };

  try {
    return new Promise((resolve, reject) => {
      return uriToBlob(image).then((blob) => {
        uploadToFirebase(blob)
          .then((result: any) => {
            resolve(
              "https://firebasestorage.googleapis.com/v0/b/" +
                storageName +
                "/o/" +
                result.metadata.fullPath +
                "?alt=media"
            );
          })
          .catch((error) => {
            console.log("error", error);
            reject(error);
          });
      });
    });
  } catch (error) {
    console.log(error);
  }
};
