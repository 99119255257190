import useStyles from "./styles";
import { MouseEventHandler } from "react";
import { Button, CircularProgress } from "@mui/material";
import classNames from "classnames";
import { CustomTypography } from "../CustomTypography/CustomTypography";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  label: string;
  variant?: "primary" | "secondary" | "white" | "default" | "withIcon";
  textVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "link" | "default";
  disabled?: boolean | undefined;
  styles?: any;
  width?: string | number;
  height?: string | number;
  padding?: string | number;
  loading?: boolean;
  fontWeight?: string | undefined;
  marginBottom?: string | number;
};

export const CustomButton: React.FC<Props> = ({
  onClick,
  label,
  variant = "default",
  textVariant = "h5",
  disabled,
  styles,
  width,
  padding,
  loading,
  marginBottom,
  height,
  fontWeight,
}) => {
  const classes = useStyles({
    width,
    padding,
    marginBottom,
    height,
  });

  let variantClasses;
  switch (variant) {
    case "default":
      variantClasses = classes.variantDefault;
      break;
    case "primary":
      variantClasses = classes.variantPrimary;
      break;
    case "secondary":
      variantClasses = classes.variantSecondary;
      break;
    case "white":
      variantClasses = classes.variantWhite;
      break;
    case "withIcon":
      variantClasses = classes.variantWithIcon;
      break;
    default:
      break;
  }

  return (
    <Button
      disabled={loading || disabled}
      onClick={onClick}
      className={classNames(
        classes.buttonRoot,
        variantClasses,
        styles?.buttonRoot
      )}
      startIcon={
        loading && <CircularProgress size={16} thickness={3} color="inherit" />
      }
    >
      <CustomTypography
        text={label}
        variant={textVariant}
        fontWeight={fontWeight}
      />
    </Button>
  );
};
