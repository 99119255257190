import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles({
  divSociety: {
    borderRadius: "10px",
    paddingTop: "17px",
    paddingBottom: "17px",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "flex",
    flexDirection: "row",
    gap: "25px",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.WHITE,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
  },
  divText: { display: "flex", flexDirection: "column", gap: "15px" },
  divIcon: {
    cursor: "pointer",
  },

  divAway: {
    position: "absolute",
    top: 40,
    right: 0,
    left: 0,
    zIndex: 1,
    width: "125px",
    borderRadius: "10px",
    padding: "5px",
    backgroundColor: colors.WHITE,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
  },

  divAway2: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "6px",
    alignItems: "center",
    padding: "5px",
    gap: "8px",
    "&:hover": {
      backgroundColor: colors.default,
      cursor: "pointer",
    },
  },
});

export default useStyles;
