import { colors } from "../../styles/colors";
import useStyles from "./styles";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import { Icon } from "../Icon/Icon";
import { FC } from "react";
import { motion } from "framer-motion";

type props = {
  label: string;
  iconName: string;
  backgroundIcon: string;
};

export const CustomPreview: FC<props> = ({
  label,
  iconName,
  backgroundIcon,
}) => {
  const classes = useStyles({ backgroundColor: backgroundIcon });
  return (
    <motion.div
      initial={{ x: -100, y: 0, opacity: 0 }}
      animate={{ x: 0, y: 0, opacity: 1 }}
      transition={{ ease: "easeOut", duration: 0.5 }}
      className={classes.divCustom}
    >
      <div className={classes.divCustomIcon}>
        <Icon
          name={iconName}
          width={"14px"}
          height={"14px"}
          color={colors.WHITE}
        />
      </div>
      <CustomTypography
        text={label}
        variant={"default"}
        fontWeight={"bold"}
        color={colors.darkblack}
      />
    </motion.div>
  );
};
