import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles({
  icon: {
    backgroundColor: colors.primary,
    width: 25,
    height: 25,
    fontSize: 15,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
export default useStyles;
