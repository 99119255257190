import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import { CustomTextInput } from "../../../components/CustomTextInput/CustomTextInput";
import useStyles from "./styles";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { colors } from "../../../styles/colors";
import { Icon } from "../../../components/Icon/Icon";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import { validateEmail } from "../../../utils/regex";
import { useUpdateUserAdmin as useUpdate } from "../../../api/user";

type props = {
  open: boolean;
  userGenericInitial: TUserGeneric;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalAddUserGeneric: FC<props> = ({
  open,
  userGenericInitial,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const { mutate: updateUserGeneric, isPending } = useUpdate(onClose);
  const { t } = useTranslation();
  const [userGeneric, setUserGeneric] =
    useState<TUserGeneric>(userGenericInitial);

  useEffect(() => {
    if (userGenericInitial) {
      setUserGeneric(userGenericInitial);
    } else {
      setUserGeneric({
        id: -1,
        lastname: "",
        firstname: "",
        email: "",
        is_active: 1,
      });
    }
  }, [userGenericInitial]);

  const handleInputChange = (fieldName: string, value: string) => {
    if (userGeneric) {
      const updatedUserGeneric = { ...userGeneric, [fieldName]: value };
      setUserGeneric(updatedUserGeneric);
    }
  };

  const handleToggle = () => {
    if (userGeneric) {
      const updatedUserGeneric = {
        ...userGeneric,
        is_active: userGeneric?.is_active === 1 ? 0 : 1,
      };
      setUserGeneric(updatedUserGeneric);
    }
  };

  const onSave = () => {
    if (userGenericInitial.id !== -1) {
      if (userGeneric.firstname.trim().length !== 0) {
        if (userGeneric.lastname.trim().length !== 0) {
          if (
            userGeneric.email.trim().length !== 0 &&
            validateEmail(userGeneric.email)
          ) {
            updateUserGeneric({
              idUser: userGeneric.userID ?? -1,
              firstname: userGeneric.firstname,
              lastname: userGeneric.lastname,
              email: userGeneric.email,
              is_active: userGeneric.is_active === 1 ? 1 : 0,
            });
          } else {
            if (userGeneric.email.trim().length === 0) {
              toast.error(t("required.email", "Email is required"));
            }
            if (!validateEmail(userGeneric.email)) {
              toast.error(t("required.emailInvalid", "Invalid email"));
            }
          }
        } else {
          toast.error(t("required.lastname", "Lastname is required"));
        }
      } else {
        toast.error(t("required.firstname", "Firstname is required"));
      }
    }
    //
  };

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={
        userGeneric.id !== -1
          ? t("common.modify", "Modify")
          : t("common.add", "Add")
      }
      onClose={onClose}
      onSave={onSave}
      loadingSave={isPending}
    >
      <div className={classes.containerText}>
        <CustomTextInput
          label={`${t("common.name", "Name")} *`}
          placeholder={t("common.name", "Name")}
          marginBottom={"0px"}
          value={userGeneric?.lastname}
          onChange={(e) => handleInputChange("lastname", e.target.value)}
        />
        <CustomTextInput
          label={`${t("common.firstname", "Firstname")} *`}
          placeholder={t("common.firstname", "Firstname")}
          marginBottom={"0px"}
          value={userGeneric?.firstname}
          onChange={(e) => handleInputChange("firstname", e.target.value)}
        />
        <CustomTextInput
          label={`${t("common.email", "Email")} *`}
          placeholder={t("common.email", "Email")}
          marginBottom={"0px"}
          value={userGeneric?.email}
          onChange={(e) => handleInputChange("email", e.target.value)}
        />
        <div className={classes.divCheckBox}>
          <CustomTypography
            text={t("common.actived", "Actived")}
            variant={"default"}
            color={colors.darkblack}
          />
          {Number(userGeneric?.is_active) === 1 ? (
            <motion.div
              className={classes.divCheckBox}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.5 }}
            >
              <Icon
                onClick={handleToggle}
                name={"toggleLeft"}
                height={"24px"}
                width={"24px"}
                style={{ cursor: "pointer" }}
              />
            </motion.div>
          ) : (
            <motion.div
              className={classes.divCheckBox}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.5 }}
            >
              <Icon
                onClick={handleToggle}
                name={"toggleRight"}
                height={"24px"}
                width={"24px"}
                style={{ cursor: "pointer" }}
              />
            </motion.div>
          )}
          <CustomTypography
            text={t("common.deactived", "Deactived")}
            variant={"default"}
            color={colors.darkblack}
          />
        </div>
      </div>
    </CustomModal>
  );
};
