import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

type Props = {
  marginBottom?: string | number;
};

const useStyles = createUseStyles((_) => ({
  container: {
    marginBottom: (props: Props) =>
      props.marginBottom ? props.marginBottom : "15px",
  },
  formControl: {
    "&.MuiFormControl-root": {
      width: "100%",
      marginTop: "5px",
      "& input": {
        opacity: 1,
        border: "none",
        backgroundColor: "transparent",
        fontSize: "13px",
        paddingLeft: "24px",
        fontFamily: "Poppins-Regular",
        paddingBottom: "9px",
        "&::placeholder": {
          color: "#b6b5b5",
        },
      },
      "& .MuiSelect-select.MuiSelect-outlined": {
        opacity: 0,
      },
      "& fieldset": {
        border: "none",
      },
      "& .MuiInputBase-root.MuiOutlinedInput-root": {
        fontFamily: "Poppins-Regular",
        backgroundColor: colors.default,
        border: `2px solid ${colors.WHITE}`,
        borderRadius: "50px",
        fontSize: "13px",
        color: colors.BLACK,
        padding: 0,
        "& .MuiSelect-select": {
          padding: "10px",
          paddingLeft: "23px",
        },
      },
    },
  },
  containerLabel: {
    paddingLeft: "26px",
  },
}));
export default useStyles;
