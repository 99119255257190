import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  checkbox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
    cursor: "pointer",
  },
});

export default useStyles;
