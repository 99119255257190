import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  menuItem: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& > div": {
      marginLeft: "10px",
    },
    height: 40,
    borderRadius: 15,
    marginBottom: 10,
    "&:hover": {
      // backgroundColor: "#312D2D26",
      backgroundColor: colors.WHITE,
    },
    "&.active": {
      backgroundColor: colors.WHITE,
    },
  },
  inverseMenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingRight: "20px",
  },
}));

export default useStyles;
