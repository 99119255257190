import {
  FormControl,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { ChangeEventHandler, FC, useEffect, useMemo, useState } from "react";
import useStyles from "./styles";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import { CustomButtonTable } from "../CustomButtonTable/CustomButtonTable";
import { CustomButtonModal } from "../CustomButtonModal/CustomButtonModal";
import { Icon } from "../Icon/Icon";
import IconButton from "@mui/material/IconButton";
import { colors } from "../../styles/colors";
import { CustomCheckBox } from "../CustomCheckBox/CustomCheckBox";
import { CustomIconButton } from "../CustomIconButton/CustomIconButton";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

type Props = {
  columns: Column[];
  data: Record<string, any>[];
  titleHeader?: string;
  isSearchable?: boolean;
  labelButtonHeader?: string;
  labelButtonFooter?: string;
  iconNameButton?: string;
  showIconEye?: boolean;
  tableMaxHeight?: string | number;
  tableMinHeight?: string | number;
  rowsPerPageOptions?: number[];
  showPagination?: boolean;
  showSelectedField?: boolean;
  iconButton?: string;
  isBackgroundUnique?: boolean;
  componentHeader?: React.ReactNode;
  tablePagnationCount?: number;
  isFilterFromBackend?: boolean;
  handleChangePageProps?: (newPage: number) => void;
  onSelectRow?: (selectedRows: Record<string, any>[]) => void;
  onChangeSearchTerm?: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  onClickButtonFooter?: () => void;
  onClickButtonEye?: () => void;
  onClickIconButton?: () => void;
  resetPaginationTrigger?: boolean;
};

type Column = {
  header: string;
  field: string;
  render?: (rowData: Record<string, any>) => React.ReactNode;
};

export const CustomTable: FC<Props> = ({
  columns,
  data,
  tablePagnationCount,
  titleHeader,
  isSearchable = false,
  labelButtonHeader,
  labelButtonFooter,
  iconNameButton,
  showIconEye,
  tableMaxHeight,
  tableMinHeight,
  rowsPerPageOptions = [10],
  showPagination = true,
  showSelectedField,
  iconButton,
  isBackgroundUnique = false,
  isFilterFromBackend = false,
  componentHeader,
  onSelectRow,
  handleChangePageProps,
  onChangeSearchTerm,
  onClickButtonFooter,
  onClickButtonEye,
  onClickIconButton,
  resetPaginationTrigger = false,
}) => {
  const classes = useStyles({
    isSearchable,
    showPagination,
    isBackgroundUnique,
    isTitleHeader: titleHeader ? true : false,
  });

  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [selectedRows, setSelectedRows] = useState<Record<string, any>[]>([]);

  const resetPagination = () => {
    setPage(0);
    setRowsPerPage(rowsPerPageOptions[0]);
  };

  useEffect(() => {
    resetPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPaginationTrigger]);

  const handleChangePage = (_: unknown, newPage: number) => {
    handleChangePageProps && handleChangePageProps(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const requestSort = (key: string) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleSelectRow = (row: Record<string, any>) => {
    const selectedIndex = selectedRows.findIndex(
      (selectedRow) => selectedRow === row
    );

    let newSelected: Record<string, any>[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);

    if (onSelectRow) {
      onSelectRow(newSelected);
    }
  };

  const handleSelectAllRows = () => {
    const allRowsSelected = selectedRows.length === data.length;

    const newSelectedRows = allRowsSelected ? [] : [...data];

    setSelectedRows(newSelectedRows);

    if (onSelectRow) {
      onSelectRow(newSelectedRows);
    }
  };

  // Appliquer le tri aux données paginées
  const sortedAndPaginatedData = useMemo(() => {
    // Utilisez le tableau de données brut pour le tri et la pagination
    let updatedData = data?.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

    if (sortConfig) {
      updatedData = [...updatedData].sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    return updatedData;
  }, [data, page, rowsPerPage, sortConfig]);

  const isSearch = searchTerm?.trim()?.length > 0;

  const filteredDataSearchTerm = data?.filter((item) =>
    Object?.values(item)?.some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase())
    )
  );

  // Appliquer la recherche aux données triées et paginées
  const filteredDataPagination = sortedAndPaginatedData?.filter((item) =>
    Object.values(item).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase())
    )
  );

  const filteredData = isFilterFromBackend
    ? data
    : isSearch
    ? filteredDataSearchTerm
    : filteredDataPagination;
  return (
    <motion.div
      className={classes.container}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className={classes.headerContainer}>
        {titleHeader && titleHeader?.trim()?.length > 0 && (
          <CustomTypography
            variant={"h5"}
            text={titleHeader}
            isUppercase
            color={colors.darkblack}
          />
        )}

        {labelButtonHeader && labelButtonHeader?.trim()?.length > 0 && (
          <CustomButtonTable
            variant={"secondary"}
            textVariant={"link"}
            label={labelButtonHeader}
            width={"fit-content"}
            fontWeight={"bold"}
            textDecoration={"none"}
            iconName={iconNameButton}
            padding={"0px 10px 0px 10px"}
          />
        )}

        {isSearchable && (
          <FormControl
            sx={{ width: "27ch" }}
            className={classes.formControl}
            variant="outlined"
          >
            <OutlinedInput
              placeholder={t("common.find", "Find")}
              // onChange={(e) => setSearchTerm(e.target.value)}
              onChange={
                isFilterFromBackend
                  ? onChangeSearchTerm
                  : (e) => setSearchTerm(e.target.value)
              }
              endAdornment={
                <InputAdornment position="end">
                  <Icon
                    name="search"
                    color={colors.darkblack}
                    width={"15px"}
                    height={"15px"}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
        )}

        {componentHeader && (
          <div className={classes.componentHeader}>{componentHeader}</div>
        )}

        {iconButton && iconButton?.trim()?.length > 0 && (
          <CustomIconButton
            iconName={iconButton}
            widthIcon={"20px"}
            heightIcon={"20px"}
            onClick={onClickIconButton}
          />
        )}
      </div>

      <TableContainer
        className={classes.containerTable}
        style={{
          maxHeight: tableMaxHeight,
          overflowY: "auto",
          minHeight: tableMinHeight,
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {showSelectedField && (
                <TableCell>
                  <CustomCheckBox
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < data.length
                    }
                    checked={selectedRows.length === data.length}
                    onChange={handleSelectAllRows}
                  />
                </TableCell>
              )}

              {columns.map((column, index) => (
                <TableCell key={index}>
                  <div className={classes.iconSort}>
                    <CustomTypography
                      text={column.header}
                      variant={"link"}
                      onClick={() => requestSort(column.field)}
                    />
                    {sortConfig && sortConfig.key === column.field && (
                      <>
                        {sortConfig.direction === "asc" ? (
                          <Icon name="chevronUp" />
                        ) : (
                          <Icon name="chevronDown" />
                        )}
                      </>
                    )}
                  </div>
                </TableCell>
              ))}
              {showIconEye && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.map((item, rowIndex) => (
              <TableRow key={rowIndex}>
                {showSelectedField && (
                  <TableCell>
                    <CustomCheckBox
                      checked={selectedRows.indexOf(item) !== -1}
                      onChange={() => handleSelectRow(item)}
                    />
                  </TableCell>
                )}
                {columns.map((column, colIndex) => (
                  <TableCell key={colIndex}>
                    {column?.render ? (
                      column?.render(item)
                    ) : (
                      <CustomTypography
                        text={item[column.field]}
                        variant="default"
                      />
                    )}
                  </TableCell>
                ))}

                {showIconEye && (
                  <TableCell>
                    <IconButton onClick={onClickButtonEye}>
                      <Icon
                        name="eye"
                        width={"24px"}
                        height={"24px"}
                        color={colors.BLACK}
                      />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={classes.footerContainer}>
        {!isSearch && showPagination && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={tablePagnationCount ? tablePagnationCount : data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} sur ${count}`
            }
            labelRowsPerPage={""}
          />
        )}
        {labelButtonFooter && labelButtonFooter?.trim()?.length > 0 && (
          <CustomButtonModal
            variant={"default"}
            textVariant={"link"}
            label={labelButtonFooter}
            width={"fit-content"}
            textDecoration={"none"}
            iconName={"chevronRight"}
            padding={"0px 10px 0px 10px"}
            onClick={onClickButtonFooter}
          />
        )}
      </div>
    </motion.div>
  );
};
