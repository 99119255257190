import { colors } from "../../styles/colors";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import useStyles from "./styles";
import { FC } from "react";

type props = {
  label: string;
  backgroundColor?: string;
  colorText?: string;
};

export const CustomBadgeText: FC<props> = ({
  label,
  backgroundColor,
  colorText,
}) => {
  const classes = useStyles({ backgroundColor });
  return (
    <div className={classes.divText}>
      <CustomTypography
        text={label}
        variant={"link"}
        color={colorText ? colorText : colors.darkpurple}
        isUppercase
      />
    </div>
  );
};
