import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../../containers/Sidebar/Sidebar";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { CustomButtonTable } from "../../components/CustomButtonTable/CustomButtonTable";
import { colors } from "../../styles/colors";
import { CustomPreview } from "../../components/CustomPreview/CustomPreview";
import { CustomSearchWithoutSolo } from "../../components/CustomSearchWithoutSolo/CustomSearchWithoutSolo";
import { CustomCreateSociety } from "../../containers/CustomCreateSociety/CustomCreateSociety";
import { ModalAddSociety } from "../../containers/CustomCreateSociety/ModalAddSociety/ModalAddSociety";
import { useState } from "react";
import { useGetAllCompanies } from "../../api/companies";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";
type Props = {};

export const Compagnies: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { companies, isPending: loadingCompanies } = useGetAllCompanies();

  const [openModalAddSociety, setOpenModalAddSociety] =
    useState<boolean>(false);

  const handleClose = () => {
    setOpenModalAddSociety(false);
  };

  return (
    <>
      <CustomLoader isActive={loadingCompanies} />
      <ModalAddSociety
        compagnieInitial={{
          id: -1,
          name: "",
          registration_id: "",
          logo: "",
          is_fleet: 0,
          is_rental: 0,
        }}
        open={openModalAddSociety}
        onCancel={handleClose}
        onClose={handleClose}
      />
      <Sidebar
        styles={{
          sidebarRightRoot: classes.vehiculeRoot,
          sidebarRoot: classes.contentMain,
        }}
      >
        <div className={classes.containerHeader}>
          <CustomTypography
            variant="h2"
            text={t("compagnies.companyManagement", "Company management")}
            isUppercase
          />
          <CustomButtonTable
            variant={"secondary"}
            textVariant={"link"}
            label={t("compagnies.addACompany", "Add a company")}
            width={"fit-content"}
            fontWeight={"bold"}
            textDecoration={"none"}
            iconName={"plus"}
            padding={"0px 15px"}
            textTransform="uppercase"
            onClick={() => setOpenModalAddSociety(true)}
          />
        </div>
        <div className={classes.divApercu}>
          <CustomTypography
            text={t("common.preview", "Preview")}
            variant={"h5"}
            isUppercase
          />
          <div className={classes.divApercuFilter}>
            <div className={classes.divApercu2}>
              {!loadingCompanies && (
                <CustomPreview
                  label={`${t("compagnies.numberOfCompanies", {
                    nbUsers: companies.length,
                  })} `}
                  iconName="userCircle"
                  backgroundIcon={colors.secondary}
                />
              )}

              {/* <CustomPreview
                label={"..."}
                iconName="help"
                backgroundIcon={colors.error}
              /> */}
            </div>
          </div>
        </div>
        <div className={classes.containerCompagnies}>
          <div className={classes.divTitle}>
            <CustomTypography
              text={t("compagnies.listOfCompanies", "List of companies")}
              variant={"h5"}
              isUppercase
            />
            <CustomSearchWithoutSolo />
          </div>
          <div className={classes.divCompagnies}>
            {companies.map((companie) => (
              <CustomCreateSociety companie={companie} key={companie.id} />
            ))}
          </div>
        </div>
      </Sidebar>
    </>
  );
};
