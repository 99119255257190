import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "./axios";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const companiesKeys = {
  all: () => ["companies"] as const,
  companie: () => ["companie"] as const,
  companieByID: (id: number) =>
    [...companiesKeys.companie(), "id", id] as const,
  companieEmployee: (idCompanie: number) =>
    ["companieEmployee", "id", idCompanie] as const,
};

export const useGetAllCompanies = () => {
  const { data, error, ...res } = useQuery({
    queryKey: companiesKeys.all(),
    queryFn: async (): Promise<TCompanie[]> => {
      const data = await axios.get(`/admin/companies`);
      return data?.data?.companies;
    },
  });
  if (error) {
  }
  return { companies: data ?? [], ...res };
};

export const useGetOneCompanie = (id: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: companiesKeys.companieByID(id),
    queryFn: async (): Promise<TCompanie> => {
      const data = await axios.get(`/admin/companies/${id}`);
      return data.data;
    },
    enabled: !Number.isNaN(id),
  });
  if (error) {
  }
  return { companie: data, ...res };
};

export interface RequestCompanie {
  id?: number;
  name: string;
  registration_id: string;
  logo: string;
  is_fleet: number;
  is_rental: number;
}

export const useCreateCompanie = (
  onClose?: () => void,
  initCompanie?: () => void
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newCompanie: RequestCompanie) => {
      const data = await axios.post("/admin/companies", newCompanie);
      return data.data;
    },
    onSuccess: (data) => {
      onClose && onClose();
      initCompanie && initCompanie();
      const keys = companiesKeys.all();
      const prev = queryClient.getQueryData<TCompanie[]>(keys);
      if (prev && data) {
        queryClient.setQueryData<TCompanie[]>(keys, [...prev, data]);
      }
      toast.success(t("compagnies.addSuccessful", "Change saved"));
    },
    onError: (err: any) => {
      if (err?.response?.data?.code === "23000") {
        toast.error(t("compagnies.alreadyExist", "Companie already exists"));
      } else {
        toast.error(err?.response?.data?.message);
      }
    },
  });
};

export const useUpdateCompanie = (
  onClose?: () => void,
  initCompanie?: () => void
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newCompanie: RequestCompanie) => {
      const data = await axios.put(
        `/admin/companies/${newCompanie.id}`,
        newCompanie
      );
      return data.data;
    },
    onSuccess: (data) => {
      onClose && onClose();
      initCompanie && initCompanie();
      const keys = companiesKeys.all();
      const prev = queryClient.getQueryData<TCompanie[]>(keys);
      if (prev && data) {
        const res = prev.map((p) => {
          if (p.id === data.id) {
            return data;
          }
          return p;
        });
        queryClient.setQueryData<TCompanie[]>(keys, res);
      }
      toast.success(t("compagnies.updateSuccess", "Change saved"));
    },
    onError: (err: any) => {
      if (err?.response?.data?.code === "23000") {
        toast.error(t("compagnies.alreadyExist", "Companie already exists"));
      } else {
        toast.error(err?.response?.data?.message);
      }
    },
  });
};

export const useDeleteCompanie = (
  idCompanie: number,
  onClose?: () => void,
  initCompanie?: () => void
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (idCompanie: number) => {
      const data = await axios.delete(`/admin/companies/${idCompanie}`);
      return data.data;
    },
    onSuccess: (_) => {
      onClose && onClose();
      initCompanie && initCompanie();

      const keys = companiesKeys.all();
      const prev = queryClient.getQueryData<TCompanie[]>(keys);
      if (prev) {
        const res = prev.filter((companie) => companie.id !== idCompanie);
        queryClient.setQueryData<TCompanie[]>(keys, res);
      }
      toast.success(t("compagnies.deleteSuccess", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export const useGetAllCompanieEmployee = (idCompanie: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: companiesKeys.companieEmployee(idCompanie),
    queryFn: async (): Promise<TCompanieEmployee[]> => {
      const data = await axios.get(`/companies/${idCompanie}/employees`);
      return data.data.users;
    },
  });
  if (error) {
  }
  return { employees: data ?? [], ...res };
};

export enum UserRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  FLEET_MANAGER = "FLEET_MANAGER",
  EMPLOYEE = "EMPLOYEE",
}

interface RequestInviteEmployee {
  role: UserRole;
  email: string;
  firstname: string;
  lastname: string;
}

export const useInviteEmployeeCompanie = (
  idCompanie: number,
  onClose?: () => void
) => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async (user: RequestInviteEmployee) => {
      const data = await axios.post(
        `/companies/${idCompanie}/employees/invitation`,
        user
      );
      return data.data;
    },
    onSuccess: (_) => {
      onClose && onClose();
      toast.success(t("common.sendInvitation", "Invitation send"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export const useChangeRoleEmployeeCompanie = (
  idCompanie: number,
  idUser: number
) => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async (user: RequestInviteEmployee) => {
      const data = await axios.post(
        `/companies/${idCompanie}/employees/${idUser}/change-role`,
        user
      );
      return data.data;
    },
    onSuccess: (_) => {
      toast.success(t("common.changeRole", "The role has been changed"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};
