import { Location } from "react-router-dom";
import i18n from "../i18n/i18n";

export const getMessageHeader = (location: Location<any>) => {
  if (location.pathname.includes("/admin")) {
    return i18n.t("admin.administratorManagement", "Administrator management");
  } else if (location.pathname.includes("/users")) {
    return i18n.t("user.userManagement", "User management");
  } else {
    return "Ceci est un bug header";
  }
};

export const getMessageCountUser = (
  userCount: number,
  location: Location<any>
) => {
  if (location.pathname.includes("/admin")) {
    return `${userCount} ${i18n.t("admin.administrators", "ADMINISTRATORS")}`;
  } else if (location.pathname.includes("/users")) {
    return i18n.t("user.countUsers", { nbUsers: userCount });
  } else {
    return "Ceci est un bug header";
  }
};

export const getTitleHeaderTable = (location: Location<any>) => {
  if (location.pathname.includes("/admin")) {
    return i18n.t("admin.listOfAdministrators", "List of administrators");
  } else if (location.pathname.includes("/users")) {
    return i18n.t("user.usersList", "Users list");
  } else {
    return "Ceci est un bug header";
  }
};
