import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import { CustomTextInput } from "../../../components/CustomTextInput/CustomTextInput";
import useStyles from "./styles";
import toast from "react-hot-toast";
import { validateEmail } from "../../../utils/regex";
import { UserRole, useInviteEmployeeCompanie } from "../../../api/companies";

type props = {
  open: boolean;
  companieId: number;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalInvitFleetManager: FC<props> = ({
  open,
  companieId,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  // const [role, setRole] = useState<UserRole>(UserRole.FLEET_MANAGER);
  const { mutate: invitUser, isPending } = useInviteEmployeeCompanie(
    companieId,
    onClose
  );

  // const onChange = (event: SelectChangeEvent<unknown>, _: ReactNode) => {
  //   setRole(event.target.value as UserRole);
  // };

  const onSave = () => {
    if (!validateEmail(email)) {
      toast.error(t("required.emailInvalid", "Invalid email"));
    } else {
      if (firstname.trim().length === 0 || lastname.trim().length === 0) {
        toast.error(t("required.allFieldsRequired", "All fields are required"));
      } else {
        invitUser({ role: UserRole.FLEET_MANAGER, email, firstname, lastname });
      }
    }
  };

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={t("user.invitUser", "Invit user")}
      isConfirmation={true}
      textConfirmation={t("common.send", "Send")}
      onClose={onClose}
      onConfirm={onSave}
      loadingConfirmation={isPending}
    >
      <div className={classes.containerText}>
        {/* {companieId === 0 && isCurrentUserSuperAdmin() === 1 && (
          <CustomTextSelect
            label="Rôle"
            marginBottom={"0px"}
            options={[UserRole.SUPER_ADMIN, UserRole.FLEET_MANAGER]}
            defaultValue={role}
            onChange={onChange}
          />
        )} */}
        <CustomTextInput
          label={`${t("user.name", "Name")} *`}
          placeholder={`${t("user.name", "Name")} *`}
          marginBottom={"0px"}
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
        />
        <CustomTextInput
          label={`${t("user.firstname", "Firstname")} *`}
          placeholder={`${t("user.firstname", "Firstname")} *`}
          marginBottom={"0px"}
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
        />
        <CustomTextInput
          label={`${t("common.email", "Email")} *`}
          placeholder={t("common.email", "Email")}
          marginBottom={"0px"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
    </CustomModal>
  );
};
