export enum MintStatus {
  "ignoré" = "ignored",
  "à valider pour mint" = "to-be-validated",
  "à demander pour mint" = "to-be-subscribed",
  "en cours de mint" = "subscribing",
  "Minter" = "subscribed",
  "échoué" = "failed",
}

export const getKeyLabelByValueMintStatus = (
  value:
    | "ignored"
    | "to-be-validated"
    | "to-be-subscribed"
    | "subscribing"
    | "subscribed"
    | "failed"
): string | undefined => {
  return Object.entries(MintStatus).find(
    ([_, enumValue]) => enumValue === value
  )?.[0];
};

//  ON_ORDER
//  AFFECTED
//  FREE
//  WAITING_FOR_VALIDATION

export enum VehicleStatus {
  "Sur commande" = "O",
  "Affecté" = "A",
  "Libre" = "F",
  "En attente de validation" = "W",
}

export const getKeyLabelByValue = (
  value: "O" | "A" | "F" | "W"
): string | undefined => {
  return Object.entries(VehicleStatus).find(
    ([_, enumValue]) => enumValue === value
  )?.[0];
};

export const getStatusFromValues = (
  value: string
): "COMMAND" | "AFFECTED" | "FREE" | "ACTIF" | "WAITING" => {
  switch (value) {
    case "O":
      return "COMMAND";

    case "A":
      return "AFFECTED";

    case "F":
      return "FREE";

    case "W":
      return "WAITING";

    default:
      return "FREE";
  }
};
