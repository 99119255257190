import { createUseStyles } from "react-jss";
const useStyles = createUseStyles((_) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "10px 15px 20px 15px",
  },
  divContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& > div": {
      flex: 1,
    },
  },
}));

export default useStyles;
