import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

const useStyles = createUseStyles((_) => ({
  drawerPositioner: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 999,
  },
  container: {
    backgroundColor: colors.secondary,
    height: "calc(100vh - 32px)",
    overflow: "hidden",
    padding: "16px 0",
    position: "relative",
    overflowY: "auto",
    transition: "width 200ms ease",
  },
  iconCollapse: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 25,
    textAlign: "center",
    cursor: "pointer",
    color: colors.WHITE,
  },
  heading: {
    width: 200,
    margin: "auto",
    // height: 120,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  headingNotCollapsed: {
    margin: "auto",
    // height: 120,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 40,
    "& img": {
      width: 40,
    },
  },
  searchTextfield: {
    "& > div": {
      backgroundColor: colors.WHITE,
      "& input.MuiInputBase-input": {
        color: colors.BLACK,
        padding: "7px 15px",
        fontSize: 11,
      },
    },
  },
  textfieldNotCollapsed: {
    width: 40,
    "& > div": {
      backgroundColor: colors.WHITE,
    },
    "& input.MuiInputBase-input": {
      padding: "7px 2px",
    },
  },
  menuItemContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "calc(100vh - 21px)",
    flexDirection: "column",
    gap: 25,
    alignItems: "center",
  },

  icon: {
    height: 20,
    display: "inline-block",
  },
  text: {
    marginLeft: 12,
  },
}));

export default useStyles;
