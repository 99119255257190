import { CSSProperties } from "react";
import {
  TbDashboard,
  TbUserCircle,
  TbCar,
  TbInfoSquareRounded,
  TbCarCrash,
  TbCertificate,
  TbLicense,
  TbSteeringWheel,
  TbMap2,
  TbStars,
  TbHelp,
  TbSettings,
  TbLogout,
  TbTrash,
  TbPencil,
  TbCards,
  TbShieldCheck,
  TbEye,
  TbFileStack,
  TbBell,
  TbChevronRight,
  TbSunHigh,
  TbSnowflake,
  TbPlus,
  TbChevronDown,
  TbChevronUp,
  TbCheck,
  TbPhoto,
  TbCertificate2,
  TbX,
  TbSearch,
  TbReload,
  TbBellRinging2,
  TbDots,
  TbDotsVertical,
  TbMailOpened,
  TbMail,
  TbTextWrapDisabled,
  TbLayout,
  TbLockSquareRounded,
  TbBuildingSkyscraper,
  TbTextWrap,
  TbCoinEuro,
  TbLogin,
  TbToggleRight,
  TbToggleLeft,
  TbArrowLeft,
  TbHourglassLow,
  TbBuildingWarehouse,
  TbPaperclip,
  TbSquare,
  TbArrowsLeftRight,
  TbSquareCheck,
} from "react-icons/tb";

type Props = {
  name: string;
  width?: number | string;
  height?: number | string;
  animation?: number | string;
  color?: any;
  style?: CSSProperties;
  onClick?: () => void;
};

// Aligner ces icons par ordres alphabétiques
export const Icon: React.FC<Props> = ({
  name,
  width,
  height,
  color,
  animation,
  style,
  onClick,
}) => {
  switch (name) {
    case "arrowLeft":
      return (
        <TbArrowLeft
          style={{ width, height, color, ...style }}
          onClick={onClick}
        />
      );

    case "bell":
      return <TbBell style={{ width, height, color }} />;
    case "bell2":
      return <TbBellRinging2 style={{ width, height, color }} />;
    case "buildingSkyscraper":
      return <TbBuildingSkyscraper style={{ width, height, color }} />;
    case "buildingWarehouse":
      return <TbBuildingWarehouse style={{ width, height, color }} />;

    case "cards":
      return <TbCards style={{ width, height }} />;
    case "certificate":
      return <TbCertificate style={{ width, height }} />;
    case "certificate2":
      return <TbCertificate2 style={{ width, height }} />;
    case "chevronRight":
      return <TbChevronRight style={{ width, height }} />;
    case "chevronDown":
      return <TbChevronDown style={{ width, height }} />;
    case "chevronUp":
      return <TbChevronUp style={{ width, height }} />;
    case "check":
      return (
        <TbCheck style={{ width, height, color, ...style }} onClick={onClick} />
      );
    case "close":
      return <TbX style={{ width, height, color }} />;
    case "car":
      return <TbCar style={{ width, height, color }} />;
    case "coinEuro":
      return <TbCoinEuro style={{ width, height, color }} />;

    case "dashboard":
      return <TbDashboard style={{ width, height }} />;
    case "dots":
      return <TbDots style={{ width, height }} />;
    case "dotsVertical":
      return (
        <TbDotsVertical
          style={{ width, height, color, ...style }}
          onClick={onClick}
        />
      );

    case "eye":
      return (
        <TbEye style={{ width, height, color, ...style }} onClick={onClick} />
      );

    case "fileStack":
      return <TbFileStack style={{ width, height }} />;

    case "help":
      return <TbHelp style={{ width, height, color, ...style }} />;

    case "hourglassLow":
      return <TbHourglassLow style={{ width, height, color, ...style }} />;

    case "login":
      return (
        <TbLogin
          style={{ width, height, color, animation, ...style }}
          onClick={onClick}
        />
      );
    case "layout":
      return <TbLayout style={{ width, height }} />;
    case "license":
      return <TbLicense style={{ width, height, color }} />;
    case "logout":
      return <TbLogout style={{ width, height }} />;
    case "lockSquareRounded":
      return <TbLockSquareRounded style={{ width, height }} />;
    case "arrowLeftRight":
      return <TbArrowsLeftRight style={{ width, height }} />;

    case "map2":
      return <TbMap2 style={{ width, height }} />;
    case "mailOpened":
      return <TbMailOpened style={{ width, height, color }} />;
    case "mail":
      return <TbMail style={{ width, height, color }} />;

    case "notification":
      return <TbInfoSquareRounded style={{ width, height }} />;

    case "pencil":
      return (
        <TbPencil
          style={{ width, height, color, ...style }}
          onClick={onClick}
        />
      );
    case "plus":
      return <TbPlus style={{ width, height, color }} />;
    case "photo":
      return <TbPhoto style={{ width, height, color }} />;
    case "paperClip":
      return (
        <TbPaperclip
          style={{ width, height, color, ...style }}
          onClick={onClick}
        />
      );

    case "reload":
      return (
        <TbReload
          style={{ width, height, color, animation, ...style }}
          onClick={onClick}
        />
      );

    case "sinistre":
      return <TbCarCrash style={{ width, height }} />;
    case "stars":
      return <TbStars style={{ width, height }} />;
    case "steeringWheel":
      return <TbSteeringWheel style={{ width, height }} />;
    case "search":
      return <TbSearch style={{ width, height, color }} />;
    case "settings":
      return <TbSettings style={{ width, height }} />;
    case "shieldCheck":
      return <TbShieldCheck style={{ width, height }} />;
    case "sunHigh":
      return <TbSunHigh style={{ width, height }} />;
    case "snowFlake":
      return <TbSnowflake style={{ width, height }} />;
    case "square":
      return (
        <TbSquare
          style={{ width, height, color, ...style }}
          onClick={onClick}
        />
      );
    case "squareCheck":
      return (
        <TbSquareCheck
          style={{ width, height, color, ...style }}
          onClick={onClick}
        />
      );

    case "userCircle":
      return <TbUserCircle style={{ width, height, color }} />;

    case "toggleRight":
      return (
        <TbToggleRight
          style={{ width, height, color, ...style }}
          onClick={onClick}
        />
      );
    case "toggleLeft":
      return (
        <TbToggleLeft
          style={{ width, height, color, ...style }}
          onClick={onClick}
        />
      );
    case "trash":
      return (
        <TbTrash style={{ width, height, color, ...style }} onClick={onClick} />
      );
    case "textWrapDisabled":
      return <TbTextWrapDisabled style={{ width, height, color }} />;
    case "textWrap":
      return <TbTextWrap style={{ width, height, color }} />;

    default:
      return null;
  }
};
