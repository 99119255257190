import i18n from "../../i18n/i18n";
import { getCurrentCompanieId } from "../../utils/currentCompanie";

type TypeItem = {
  label: string;
  iconName: string;
  width: number;
  height: number;
  to: string;
};

const items = [] as TypeItem[];

items.push({
  label: i18n.t("sidebar.dashboard", "Dashboard"),
  iconName: "layout",
  width: 24,
  height: 24,
  to: "/dashboard",
});

items.push({
  label: i18n.t("sidebar.admin", "Admin"),
  iconName: "lockSquareRounded",
  width: 24,
  height: 24,
  to: "/admin",
});

items.push({
  label: i18n.t("sidebar.users", "Users"),
  iconName: "userCircle",
  width: 24,
  height: 24,
  to: `/users`,
});

items.push({
  label: i18n.t("sidebar.department", "Department"),
  iconName: "stars",
  width: 24,
  height: 24,
  to: `/compagnies/${getCurrentCompanieId()}/departments`,
});

items.push({
  label: i18n.t("sidebar.cars", "Cars"),
  iconName: "car",
  width: 24,
  height: 24,
  to: "/cars",
});

items.push({
  label: i18n.t("sidebar.compagnies", "Compagnies"),
  iconName: "buildingSkyscraper",
  width: 24,
  height: 24,
  to: "/compagnies",
});

// isCurrentUserSuperAdmin() === 1 &&
//   items.push({
//     label: i18n.t("sidebar.rentalCompany", "Rental Company"),
//     iconName: "buildingWarehouse",
//     width: 24,
//     height: 24,
//     to: "/rentalcompany",
//   });

items.push({
  label: i18n.t("sidebar.retranscription", "Retranscription"),
  iconName: "arrowLeftRight",
  width: 24,
  height: 24,
  to: "/retranscription",
});

items.push({
  label: i18n.t("sidebar.help", "Help"),
  iconName: "help",
  width: 24,
  height: 24,
  to: "",
});

export default items;
