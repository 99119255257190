import useStyles from "./styles";
import { useEffect, useState } from "react";
// import { items } from "./DataSideBar";
import { useNavigate } from "react-router";
import { MenuItem } from "./MenuItem/MenuItem";
import { useTranslation } from "react-i18next";
import items from "./DataSideBar";

const SIDEBAR_SIZE = 65;
const SIDEBAR_SIZE_COLLAPSED = 240;

type Props = {
  children?: React.ReactNode;
  styles?: any;
};

export const Sidebar: React.FC<Props> = ({ children, styles }) => {
  const classes = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage("fr");
    // eslint-disable-next-line
  }, []);

  const onCloseDrawer = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={styles?.sidebarRoot}>
      {/* Sidebar */}
      <div className={classes.drawerPositioner}>
        <div
          className={classes.container}
          style={{ width: isCollapsed ? SIDEBAR_SIZE_COLLAPSED : SIDEBAR_SIZE }}
        >
          <div className={classes.menuItemContainer}>
            <div>
              <MenuItem
                iconName={isCollapsed ? "textWrap" : "textWrapDisabled"}
                isCollapsed={isCollapsed}
                text={"BO"}
                width={24}
                height={24}
                onClick={onCloseDrawer}
                inverse={isCollapsed}
              />
              {items.slice(0, 7)?.map((item) => (
                <MenuItem
                  key={item?.iconName}
                  iconName={item?.iconName ?? ""}
                  isCollapsed={isCollapsed}
                  text={item?.label ?? ""}
                  width={item?.width ?? 0}
                  height={item?.height ?? 0}
                  onClick={() => {
                    setIsCollapsed(false);
                    item?.to && navigate(item.to);
                  }}
                />
              ))}
            </div>
            <div>
              {items.slice(7)?.map((item) => (
                <MenuItem
                  key={item?.iconName}
                  iconName={item?.iconName ?? ""}
                  isCollapsed={isCollapsed}
                  text={item?.label ?? ""}
                  width={item?.width ?? 0}
                  height={item?.height}
                  onClick={() => {
                    setIsCollapsed(false);
                    item?.to && navigate(item.to);
                  }}
                />
              ))}
              <MenuItem
                key={"logout"}
                iconName={"logout"}
                isCollapsed={isCollapsed}
                text={t("sidebar.logout", "Logout")}
                width={24}
                height={24}
                onClick={() => {
                  setIsCollapsed(false);
                  localStorage.clear();
                  window?.location?.reload();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* child */}
      <div
        className={styles?.sidebarRightRoot}
        style={{ marginLeft: SIDEBAR_SIZE }}
      >
        {children}
      </div>
    </div>
  );
};
