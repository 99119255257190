import Logo from "../../assets/png/logoPng.png";
import { TextField } from "@mui/material";
import { FC, useState } from "react";
import useStyles from "./styles";
import { CustomButton } from "../../components/CustomButton/CustomButton";

type props = {
  onClick: (value: string) => void;
  isloading?: boolean;
};

export const BeforeRetranscription: FC<props> = ({ onClick, isloading }) => {
  const [val, setVal] = useState("");
  const classes = useStyles();

  return (
    <div className={classes.beforeRetranscription}>
      <div>
        <img src={Logo} alt="" style={{ width: "12rem", marginBottom: 20 }} />
        <TextField
          fullWidth
          className={classes.beforeTextfield}
          id="outlined-basic"
          label="Veuillez taper votre immatriculation pour afficher l'historique certifié du véhicule"
          variant="outlined"
          value={val}
          onChange={(event) => setVal(event.target.value)}
        />

        <CustomButton
          variant={"secondary"}
          textVariant={"link"}
          label={"Rechercher"}
          width={"fit-content"}
          fontWeight={"bold"}
          loading={isloading}
          padding={"0px 15px"}
          onClick={() => onClick(val)}
        />
      </div>
    </div>
  );
};
