import { useTranslation } from "react-i18next";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { IconWithBackground } from "../../../components/IconWithBackground/IconWithBackground";
import { colors } from "../../../styles/colors";
import { InfosUsers } from "../InfosUsers/InfosUsers";
import { motion } from "framer-motion";
import useStyles from "./styles";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const data = [
  {
    name: "2020",
    users: 1750,
  },
  {
    name: "2021",
    users: 2100,
  },
  {
    name: "2022",
    users: 1750,
  },
  {
    name: "2023",
    users: 3000,
  },
];

export const FleetInfos = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.divContainerFleet}>
      <motion.div
        className={classes.divFleet}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.7 }}
      >
        <div className={classes.divUsers}>
          <IconWithBackground
            iconName="userCircle"
            backgroundColor={colors.greenLight}
            iconColor={colors.greenDark}
            width={"28px"}
            height={"28px"}
          />
          <div className={classes.divChiffre}>
            <CustomTypography text={"8190"} variant={"h2"} />
            <CustomTypography
              text={t(
                "dashboard.numberOfFleetCustomers",
                "Number of fleet customers"
              )}
              variant={"default"}
            />
          </div>
        </div>
        {/* graph */}
        <div className={classes.divRecharts}>
          <ResponsiveContainer width="100%" height={"90%"}>
            <LineChart data={data}>
              <Line
                type="linear"
                dataKey="users"
                stroke="#A5DFA0"
                strokeWidth={2}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </motion.div>
      <InfosUsers />
    </div>
  );
};
