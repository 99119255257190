import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

const useStyles = createUseStyles({
  container: {
    "&.MuiCheckbox-indeterminate": {
      color: colors.primary,
    },
    "&.Mui-checked": {
      color: colors.primary,
    },
  },
});

export default useStyles;
