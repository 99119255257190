import { FC } from "react";
import useStyles from "./styles";
// import { Button } from "@mui/base";
import Verified from "../../assets/svg/verified.svg";
import InformationProprietaire from "../../assets/svg/InformationProprietaire.svg";
import dayjs from "dayjs";

type props = {
  isVerified: boolean;
  historique: TEvent;
};

export const HistoriqueItem: FC<props> = ({ isVerified, historique }) => {
  const classes = useStyles();
  return (
    <div className={classes.historiqueItemContainer}>
      <div
        className={
          isVerified ? classes.iconVerification : classes.iconProprietaire
        }
      >
        {isVerified ? (
          <img src={Verified} alt="Verified" />
        ) : (
          <img src={InformationProprietaire} alt="Infos" />
        )}
      </div>
      <div className={classes.historiqueRow}>
        <div className="year">
          {dayjs(historique?.date).format("DD/MM/YYYY")}
        </div>
        <div className="revision">{historique.eventType}</div>
        <div className={classes.notes}>
          <div className="notes">Note</div>
          <p>{historique?.name}</p>
          <p>{historique?.note}</p>
        </div>

        {/* <div>
          {historique?.url_report?.map((document, index) => (
            <Button key={index} className={classes.buttonDocument}>
              {document ?? "Titre de document"}
            </Button>
          ))}
        </div> */}
      </div>
    </div>
  );
};
