import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import useStyles from "./styles";
import { FC } from "react";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { useDeleteDepartment } from "../../../api/departments";

type props = {
  open: boolean;
  companyId: number;
  departmentInitial: TDepartment;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalDeleteDepartment: FC<props> = ({
  open,
  companyId,
  departmentInitial,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { mutate: deleteDepartment, isPending } = useDeleteDepartment(
    companyId,
    departmentInitial?.id ?? -1,
    onClose
  );

  const onConfirm = () => {
    deleteDepartment({ idCompanie: companyId });
  };

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={""}
      onClose={onClose}
      isConfirmation
      textConfirmation={t("login.newPassword", "Validate")}
      loadingConfirmation={isPending}
      onConfirm={onConfirm}
    >
      <div className={classes.textModalContainer}>
        <CustomTypography
          text={t("department.deleteDepartment", {
            name: departmentInitial?.name,
          })}
          variant="default"
        />
      </div>
    </CustomModal>
  );
};
