import { useEffect, useState } from "react";
import FullLogo from "../../assets/svg/logo_full.svg";
import Verified from "../../assets/svg/verified.svg";
import InformationProprietaire from "../../assets/svg/InformationProprietaire.svg";

import useStyles from "./styles";
import { MenuYearItem } from "./MenuYearItem";
import { HistoriqueItem } from "./HistoriqueItem";
import { BeforeRetranscription } from "./BeforeRetranscription";
import { useGetVehiculeNFT } from "../../api/retranscription";
import { Sidebar } from "../../containers/Sidebar/Sidebar";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

const Retranscription = () => {
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const immatriculation = queryParams.get("immatriculation");

  const [immat, setImmat] = useState("");

  const { vehicle, isSuccess } = useGetVehiculeNFT(
    immatriculation ? immatriculation : immat
  );

  const [isSelectedYear, setIsSelectedYear] = useState<number>(2003);

  useEffect(() => {
    if (isSuccess && vehicle?.brand && Object.keys(vehicle?.eventsByDate)) {
      const keys = Object.keys(vehicle?.eventsByDate);
      const lastKey = keys[keys.length - 1];
      setIsSelectedYear(Number(lastKey));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleChangeCurrentEvent = (date: number) => {
    setIsSelectedYear(date);
  };

  const onClickSearch = (value: string) => {
    setImmat(value);
  };

  return (
    <Sidebar
      styles={{
        sidebarRightRoot: classes.vehiculeRoot,
        sidebarRoot: classes.contentMain,
      }}
    >
      {immatriculation || vehicle?.brand ? (
        <div className={classes.container}>
          <div className={classes.banner}>
            <div>
              <div>
                <img src={FullLogo} alt="Logo" />
              </div>
              <div className={classes.titleVehicule}>
                Historique du véhicule
              </div>
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.contentLeft}>
              <div className={classes.title}>
                <div className={classes.titlevehicule2}>
                  {`${vehicle?.brand ?? ""} ${vehicle?.model ?? ""}`}
                </div>
                <div className={classes.titleNumber}>{vehicle.number}</div>
              </div>
              <div>
                <div>
                  <div
                    className={classes.menulist}
                    style={{ marginTop: "40px" }}
                  >
                    <div>Marque</div>
                    <div>{vehicle.brand}</div>
                  </div>
                  <div className={classes.menulist}>
                    <div>Modèle</div>
                    <div>{vehicle.model}</div>
                  </div>
                  <div className={classes.menulist}>
                    <div>Immatriculation</div>
                    <div>{vehicle.number}</div>
                  </div>
                  <div className={classes.menulist}>
                    <div>Boite</div>
                    <div>{vehicle.gearBox}</div>
                  </div>
                  <div className={classes.menulist}>
                    <div>Date de mise en circulation</div>
                    <div>
                      {dayjs(vehicle.dateOfFirstRegistration).format(
                        "DD/MM/YYYY"
                      )}
                    </div>
                  </div>
                  <div className={classes.menulist}>
                    <div>Carburant</div>
                    <div>{vehicle.fuel}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.contentRight}>
              <div className={classes.containerInformation}>
                <div className={classes.information}>
                  <img src={Verified} alt="Verified" />
                  <div className={classes.textInformation}>
                    Intervention vérifiée et certifiée par CodeNekt
                  </div>
                </div>
                <div className={classes.information}>
                  <img src={InformationProprietaire} alt="Infos" />
                  <div className={classes.textInformation}>
                    Information déclarée par le propriétaire
                  </div>
                </div>
              </div>
              <div className={classes.historique}>
                <div className={classes.containerHistorique}>
                  <div>Historique</div>
                  <div className={classes.containerYears}>
                    {Object.keys(vehicle?.eventsByDate).map((date, index) => {
                      if (Number(date) >= 2005) {
                        return (
                          <MenuYearItem
                            year={Number(date)}
                            key={index}
                            onClick={() =>
                              handleChangeCurrentEvent(Number(date))
                            }
                            isSelected={Number(date) === isSelectedYear}
                          />
                        );
                      }
                      return <></>;
                    })}
                  </div>
                </div>
                <div className={classes.historiqueList}>
                  {vehicle?.eventsByDate?.[isSelectedYear]?.map(
                    (event, index) => {
                      if (new Date().getFullYear() === isSelectedYear) {
                        return (
                          <HistoriqueItem
                            key={index}
                            isVerified
                            historique={event}
                          />
                        );
                      } else {
                        return null;
                      }
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <BeforeRetranscription
          onClick={(val) => onClickSearch(val)}
          isloading={false}
        />
      )}
    </Sidebar>
  );
};

export default Retranscription;
