import { useQuery } from "@tanstack/react-query";
import axios from "./axios";
import toast from "react-hot-toast";
import dayjs from "dayjs";

export const vehiculesNFTKeys = {
  getVehiculeNFT: (immat: string) => ["vehicules", "immat", immat] as const,
};

export const useGetVehiculeNFT = (licensePlate: string) => {
  const { data, error, ...res } = useQuery({
    queryKey: vehiculesNFTKeys.getVehiculeNFT(licensePlate),
    queryFn: (): Promise<any> => axios.get(`/apy3nft/${licensePlate}`),
    enabled: licensePlate.trim().length > 0,
    staleTime: 0,
  });
  if (error) {
    const as = error as any;
    toast.error(as?.response?.data?.message || "Une erreur est survenue");
  }
  const dataTrue = data as any;

  let vehicleData: TVehiculeNFT | undefined;
  let events: TEvent[] = [];
  const eventsByDate: { [date: string]: TEvent[] } = {};

  if (dataTrue?.data?.metadata?.metadata) {
    vehicleData = JSON.parse(dataTrue?.data?.metadata?.metadata);
  }
  if (dataTrue?.data?.metadata?.events) {
    dataTrue?.data?.metadata?.events.forEach((eventString: any) => {
      const key = Object.keys(eventString)[0];
      const eventData = JSON.parse(eventString[key]).data;
      events.push(eventData);
    });
    // Sort events by date
    events.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    events.forEach((event) => {
      const eventDate = dayjs(event.date).format("YYYY");
      if (!eventsByDate[eventDate]) {
        eventsByDate[eventDate] = [];
      }
      eventsByDate[eventDate].push(event);
    });
  }
  const dataToView = {
    ...vehicleData,
    // events,
    eventsByDate,
  } as TVehiculeNFT;
  return { vehicle: dataToView, ...res };
};
