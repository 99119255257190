import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import useStyles from "./styles";
import { FC } from "react";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { useUpdateUserAdmin as useUpdate } from "../../../api/user";

type props = {
  open: boolean;
  userGenericInitial: TUserGeneric;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalChangeStatusGeneric: FC<props> = ({
  open,
  userGenericInitial,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { mutate: updateUserGeneric, isPending } = useUpdate(onClose);

  const onConfirm = () => {
    updateUserGeneric({
      idUser: userGenericInitial.userID ?? -1,
      firstname: userGenericInitial.firstname,
      lastname: userGenericInitial.lastname,
      email: userGenericInitial.email,
      is_active: Number(userGenericInitial.is_active) === 1 ? 0 : 1,
    });
  };

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={""}
      onClose={onClose}
      isConfirmation
      textConfirmation={t("common.validate", "Validate")}
      onConfirm={onConfirm}
      loadingConfirmation={isPending}
    >
      <div className={classes.textModalContainer}>
        {Number(userGenericInitial.is_active) === 1 ? (
          <CustomTypography
            text={t("userGeneric.deactive", {
              name: userGenericInitial?.lastname,
              firstname: userGenericInitial?.firstname,
            })}
            variant="default"
          />
        ) : (
          <CustomTypography
            text={t("userGeneric.active", {
              name: userGenericInitial?.lastname,
              firstname: userGenericInitial?.firstname,
            })}
            variant="default"
          />
        )}
      </div>
    </CustomModal>
  );
};
