import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  divContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: "18px",
  },
});

export default useStyles;
