import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

type Props = {
  width?: string | number;
  height?: string | number;
  borderRadius?: string | number;
};

const useStyles = createUseStyles({
  uploadFile: {
    width: (props: Props) => (props.width ? props.width : "100px"),
    height: (props: Props) => (props.height ? props.height : "100px"),
    borderRadius: (props: Props) =>
      props.borderRadius ? props.borderRadius : "12px",
    border: `1px dashed ${colors.primary}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    cursor: "pointer",
  },
  imagesStyles: {
    maxWidth: "100px",
    maxHeight: "100px",
  },
});

export default useStyles;
