import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

type PropsStyle = {
  width?: string | number;
  padding?: string | number;
  marginBottom?: string | number;
  textTransform?: string;
  endIcon?: boolean;
};

const useStyles = createUseStyles(() => ({
  buttonRoot: {
    "&.MuiButton-root": {
      width: (props: PropsStyle) => props.width || "100%",
      padding: (props: PropsStyle) => props.padding || "3px 20px",
      marginBottom: (props: PropsStyle) => props.marginBottom || 0,
      textTransform: (props: PropsStyle) => props.textTransform || "none",
      height: "30px",
      borderRadius: "50px",
      margin: "0",
      border: 0,
      color: colors.primary,
      textWrap: "nowrap",

      "& > div": {
        marginLeft: "10px",
        marginRight: (props: PropsStyle) =>
          props.endIcon ? "15px" : undefined,
      },
      "&:hover": {
        backgroundColor: colors.WHITE,
      },
    },
  },
  variantDefault: {
    "&.MuiButton-root": {
      color: colors.BLACK,
      backgroundColor: colors.default,
      "&:hover": {
        backgroundColor: colors.default,
      },
    },
  },
  variantPrimary: {
    "&.MuiButton-root": {
      backgroundColor: colors.primary,
      color: colors.WHITE,
      "&:hover": {
        backgroundColor: colors.primary,
      },
    },
  },
  variantSecondary: {
    "&.MuiButton-root": {
      backgroundColor: colors.secondary,
      color: colors.WHITE,
      "&:hover": {
        backgroundColor: colors.secondary,
      },
    },
  },
  variantTertiary: {
    "&.MuiButton-root": {
      backgroundColor: colors.tertiary,
      color: colors.WHITE,
      "&:hover": {
        backgroundColor: colors.tertiary,
      },
    },
  },
  variantWhite: {
    "&.MuiButton-root": {
      backgroundColor: colors.WHITE,
      color: colors.primary,
      "&:hover": {
        backgroundColor: colors.WHITE,
      },
    },
  },
  variantWithIcon: {
    "&.MuiButton-root": {
      backgroundColor: colors.WHITE,
      color: colors.primary,
      "&:hover": {
        backgroundColor: colors.WHITE,
      },
    },
  },
}));

export default useStyles;
