export const colors = {
  darkblack: "#000000",
  BLACK: "#312D2D",
  WHITE: "#FFFFFF",
  primary: "#0898FF",
  secondary: "#FF6F08",
  tertiary: "#FF0000",
  default: "#F2F2F2",
  success: "#39B82E",
  error: "#FF0000",
  info: "#0898FF",
  bgcolor: "#F7F8F9",
  greydark: "#beb5b5",
  lightpurple: "#F3E9FF",
  darkpurple: "#5918AD",
  lightBlue: "#DEF1FF",
  darkBlue: "#1784D2",
  orangeLight: "#FDEADD",
  orangeDark: "#E19259",
  pinkLight: "#FEE6E9",
  pinkDark: "#B85656",
  greenLight: "#DCF9DA",
  greenDark: "#62975E",
  red: "#DE4444",
};
