import { IconButton, TextField } from "@mui/material";
import useStyles from "./styles";
import { ImEye } from "react-icons/im";
import { ImEyeBlocked } from "react-icons/im";
import { ImSearch } from "react-icons/im";
import { ChangeEventHandler, HTMLInputTypeAttribute, useState } from "react";
import { colors } from "../../styles/colors";
import classNames from "classnames";

type Props = {
  placeholder?: string | undefined;
  onChange?:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  type?: HTMLInputTypeAttribute | undefined;
  value?: unknown;
  size?: "medium" | "small";
  variant?: "outlined" | undefined;
  pass?: boolean;
  viewPassword?: boolean;
  isSearchable?: boolean;
  fullWidth?: boolean | undefined;
  handleClickShowPassword?: () => void;
  styles?: any;
};

export const CustomTextField: React.FC<Props> = ({
  placeholder,
  type,
  value,
  variant,
  size,
  pass,
  isSearchable,
  fullWidth,
  styles,
  onChange,
}) => {
  const classes = useStyles();
  const [viewPassword, setViewPassword] = useState(false);

  const handleClickShowPassword = () => {
    setViewPassword(!viewPassword);
  };

  let adornement;
  if (pass) {
    adornement = (
      <IconButton onClick={handleClickShowPassword}>
        {!viewPassword ? (
          <ImEye color={colors.WHITE} size={12} />
        ) : (
          <ImEyeBlocked color={colors.WHITE} size={12} />
        )}
      </IconButton>
    );
  }

  if (isSearchable) {
    adornement = (
      <IconButton onClick={handleClickShowPassword}>
        <ImSearch color={colors.BLACK} size={12} />
      </IconButton>
    );
  }

  return (
    <TextField
      className={classNames(classes.textfield, styles?.textfield)}
      placeholder={placeholder}
      onChange={onChange}
      type={pass ? (viewPassword ? "text" : "password") : type}
      size={size}
      variant={variant}
      value={value}
      fullWidth={fullWidth}
      InputLabelProps={{ shrink: false }}
      InputProps={{ endAdornment: adornement }}
    />
  );
};
