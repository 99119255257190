export const getOptionsFromObjects = <T extends { [key: string]: any }>(
  data: T[],
  labelProp: keyof T,
  valueProp: keyof T
): { label: string; value: any }[] => {
  return data.map((item) => ({
    label: item[labelProp].toString(),
    value: item[valueProp],
  }));
};

export const NO_FILTER = "Pas de filtre";
