import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../../containers/Sidebar/Sidebar";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { CustomTable } from "../../components/CustomTable/CustomTable";
import { colors } from "../../styles/colors";
import { CustomPreview } from "../../components/CustomPreview/CustomPreview";
import { useState } from "react";
import { ModalAddUserGeneric } from "./ModalAddUserGeneric/ModalAddUserGeneric";
import { ActionsTable } from "./ActionsTable/ActionsTable";
import { CustomStatus } from "../../components/CustomStatus/CustomStatus";
import { ModalSeeUserGeneric } from "./ModalSeeUserGeneric/ModalSeeUserGeneric";
import { ModalSeeConfirmConnectionGeneric } from "./ModalSeeConfirmConnectionGeneric/ModalSeeConfirmConnectionGeneric";
import { ModalSendEmailUserGeneric } from "./ModalSendEmailUserGeneric/ModalSendEmailUserGeneric";
import { ModalDeleteUserGeneric } from "./ModalDeleteUserGeneric/ModalDeleteUserGeneric";
import { ModalChangeStatusGeneric } from "./ModalChangeStatusGeneric/ModalChangeStatusGeneric";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getMessageCountUser,
  getMessageHeader,
  getTitleHeaderTable,
} from "../../utils/userGeneric";
import { useGetOneCompanie } from "../../api/companies";
import { Icon } from "../../components/Icon/Icon";
import { CustomButtonTable } from "../../components/CustomButtonTable/CustomButtonTable";
import { ModalInvitFleetManager } from "./ModalInvitFleetManager/ModalInvitFleetManager";
import { useGetAllUsers } from "../../api/user";
import { CustomCheckboxSelected } from "../../components/CustomCheckboxSelected/CustomCheckboxSelected";
import { MenuItem } from "@mui/material";
import { dataFiltersUsersGeneric } from "./data";

const columns = [
  { header: "id", field: "userID" },
  { header: "Nom", field: "lastname" },
  { header: "Prénom", field: "firstname" },
  { header: "email", field: "email" },
  { header: "company", field: "name" },
  { header: "company rôle", field: "company_role" },
];

const initUserGenericData = {
  id: -1,
  firstname: "",
  lastname: "",
  email: "",
  is_active: 1,
} as TUserGeneric;

type Props = {};

export const UsersGeneric: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const location = useLocation();

  const navigate = useNavigate();
  const { id = "" } = useParams();
  const companieId = parseInt(id);

  const { companie, isPending: loadingCompanie } = useGetOneCompanie(
    companieId ?? 0
  );

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filtersSelected, setFiltersSelected] = useState<string | null>(null);

  const {
    users,
    isPending: loadingUser,
    totalCount,
  } = useGetAllUsers(
    currentPage,
    10,
    searchTerm,
    companie?.name ?? "",
    location.pathname.includes("/admin") ? "FLEET_MANAGER" : null,
    filtersSelected
  );

  const [openModal, setOpenModal] = useState(false);
  const [openModalSee, setOpenModalSee] = useState(false);
  const [openModalConnexion, setOpenModalConnexion] = useState(false);
  const [openModalSendEmail, setOpenModalSendEmail] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalChangeStatus, setOpenModalChangeStatus] = useState(false);
  const [userGeneric, setUserGeneric] =
    useState<TUserGeneric>(initUserGenericData);
  const [openModalInvitUser, setOpenModalInvitUser] = useState(false);

  const onhandleClose = () => {
    setOpenModal(false);
    setOpenModalSee(false);
    setOpenModalSendEmail(false);
    setOpenModalConnexion(false);
    setOpenModalDelete(false);
    setOpenModalChangeStatus(false);
    setUserGeneric(initUserGenericData);
    setOpenModalInvitUser(false);
  };

  const handleEdit = (user: TUserGeneric) => {
    setOpenModal(true);
    setUserGeneric(user);
  };

  const handleSee = (user: TUserGeneric) => {
    setOpenModalSee(true);
    setUserGeneric(user);
  };

  const handleConnect = (user: TUserGeneric) => {
    setOpenModalConnexion(true);
    setUserGeneric(user);
  };

  const handleSendEmail = (user: TUserGeneric) => {
    setOpenModalSendEmail(true);
    setUserGeneric(user);
  };

  const handleDelete = (user: TUserGeneric) => {
    setOpenModalDelete(true);
    setUserGeneric(user);
  };

  const handleChangeStatus = (user: TUserGeneric) => {
    setOpenModalChangeStatus(true);
    setUserGeneric(user);
  };

  const handleChangePageProps = (newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeFilters = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFiltersSelected(e.target.value as any);
  };

  const actionsColumns = [
    {
      header: "Statut",
      field: "status",
      render: (data: TUserGeneric) => {
        const dataTrue = {
          id: data?.id,
          userID: data?.userID,
          lastname: data?.lastname,
          firstname: data?.firstname,
          email: data?.email,
          is_active: data?.is_active,
        } as TUserGeneric;
        const isActive = Number(data?.is_active) === 1 ? true : false;
        return (
          <CustomStatus
            isActive={isActive}
            onClick={() => handleChangeStatus(dataTrue)}
          />
        );
      },
    },
    {
      header: "",
      field: "",
      render: (data: TUserGeneric) => {
        const dataTrue = {
          id: data?.id,
          userID: data?.userID,
          lastname: data?.lastname,
          firstname: data?.firstname,
          email: data?.email,
          is_active: data?.is_active,
        } as TUserGeneric;
        return (
          <ActionsTable
            handleEditUserGeneric={() => handleEdit(dataTrue)}
            handleSeeUserGeneric={() => handleSee(dataTrue)}
            handleConnectUserGeneric={() => handleConnect(dataTrue)}
            handleSendEmailUserGeneric={() => handleSendEmail(dataTrue)}
            handleDeleteUserGeneric={() => handleDelete(dataTrue)}
          />
        );
      },
    },
  ];

  const columnsWithActions = [...columns, ...actionsColumns];

  return (
    <>
      <CustomLoader isActive={loadingUser} />
      <ModalInvitFleetManager
        companieId={companieId}
        open={openModalInvitUser}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />
      <ModalAddUserGeneric
        open={openModal}
        userGenericInitial={userGeneric}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />
      <ModalSeeUserGeneric
        open={openModalSee}
        userGenericInitial={userGeneric}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />
      <ModalSeeConfirmConnectionGeneric
        open={openModalConnexion}
        userGenericInitial={userGeneric}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />
      <ModalSendEmailUserGeneric
        open={openModalSendEmail}
        userGenericInitial={userGeneric}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />
      <ModalDeleteUserGeneric
        open={openModalDelete}
        userGenericInitial={userGeneric}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />
      <ModalChangeStatusGeneric
        open={openModalChangeStatus}
        userGenericInitial={userGeneric}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />
      <Sidebar
        styles={{
          sidebarRightRoot: classes.vehiculeRoot,
          sidebarRoot: classes.contentMain,
        }}
      >
        <div className={classes.containerHeader}>
          {location.pathname.includes("/compagnies") && (
            <div className={classes.divIconArrow}>
              <Icon
                style={{ cursor: "pointer" }}
                name={"arrowLeft"}
                width={"28px"}
                height={"28px"}
                onClick={() => navigate(-1)}
              />
              {!loadingCompanie && (
                <CustomTypography
                  variant="h2"
                  text={companie?.name ?? ""}
                  isUppercase
                />
              )}
            </div>
          )}
          {!location.pathname.includes("/compagnies") && (
            <CustomTypography
              variant="h2"
              text={getMessageHeader(location)}
              isUppercase
            />
          )}
          {location.pathname.includes("/compagnies") && (
            <CustomButtonTable
              variant={"secondary"}
              textVariant={"link"}
              label={t("user.invitUser", "Invit user")}
              width={"fit-content"}
              fontWeight={"bold"}
              textDecoration={"none"}
              iconName={"plus"}
              padding={"0px 15px"}
              textTransform="uppercase"
              onClick={() => setOpenModalInvitUser(true)}
            />
          )}
        </div>
        <div className={classes.divApercu}>
          <CustomTypography
            text={t("common.preview", "Preview")}
            variant={"h5"}
            isUppercase
          />
          <div className={classes.divApercuFilter}>
            <div className={classes.divApercu2}>
              {!loadingUser && (
                <CustomPreview
                  label={getMessageCountUser(totalCount ?? 0, location)}
                  iconName="userCircle"
                  backgroundIcon={colors.primary}
                />
              )}

              {/* <CustomPreview
                label={`${t("common.enabled")} : 109`}
                iconName="check"
                backgroundIcon={colors.success}
              />
              <CustomPreview
                label={`${t("common.disableds")} : 121`}
                iconName="close"
                backgroundIcon={colors.greydark}
              />
              <CustomPreview
                label={"..."}
                iconName="help"
                backgroundIcon={colors.error}
              /> */}
            </div>
            <div className={classes.divFilter}>
              <CustomTypography
                text={`${t("common.filter", "Filter")} :`}
                variant={"link"}
                fontWeight={"bold"}
                color={colors.darkblack}
              />
              <CustomCheckboxSelected
                onChange={handleChangeFilters}
                value={filtersSelected}
                SelectProps={{
                  multiple: false,
                  renderValue: (selecteds: any): string => {
                    return selecteds === "1" ? "Activé" : "Désactivé";
                  },
                }}
              >
                {dataFiltersUsersGeneric.map((p) => (
                  <MenuItem key={p.id} value={p.value}>
                    {p.name}
                  </MenuItem>
                ))}
              </CustomCheckboxSelected>
            </div>
          </div>
        </div>
        <CustomTable
          isFilterFromBackend
          columns={columnsWithActions}
          data={users}
          isSearchable
          tableMaxHeight={"520px"}
          titleHeader={getTitleHeaderTable(location)}
          tablePagnationCount={totalCount}
          handleChangePageProps={handleChangePageProps}
          onChangeSearchTerm={(e) => {
            setTimeout(() => {
              setSearchTerm(e.target.value);
            }, 1000);
          }}
        />
      </Sidebar>
    </>
  );
};
