import { Icon } from "../Icon/Icon";
import useStyles from "./styles";

type Props = {
  iconName: string;
  width?: string;
  height?: string;
  widthBackground?: string;
  heightBackground?: string;
  iconColor?: string;
  backgroundColor?: string;
};

export const IconWithBackground: React.FC<Props> = ({
  iconName,
  width = "18px",
  height = "18px",
  widthBackground = "60px",
  heightBackground = "60px",
  iconColor,
  backgroundColor,
}) => {
  const classes = useStyles({
    backgroundColor,
    widthBackground,
    heightBackground,
  });

  return (
    <div className={classes.containerIcon}>
      <Icon name={iconName} width={width} height={height} color={iconColor} />
    </div>
  );
};
