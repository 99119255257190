import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import { CustomTextInput } from "../../../components/CustomTextInput/CustomTextInput";
import toast from "react-hot-toast";
import useStyles from "./styles";
import { FC, useEffect, useState } from "react";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { colors } from "../../../styles/colors";
import { Icon } from "../../../components/Icon/Icon";
import { motion } from "framer-motion";

type props = {
  open: boolean;
  userInitial: TUserGeneric | null;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalAddService: FC<props> = ({
  open,
  userInitial,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [user, setUser] = useState<TUserGeneric | null>(userInitial);

  useEffect(() => {
    if (userInitial) {
      setUser(userInitial);
    } else {
      setUser({ id:-1,  lastname: "", firstname: "", email: "", is_active: 0 });
    }
  }, [userInitial]);

  const handleInputChange = (fieldName: string, value: string) => {
    if (user) {
      const updatedUser = { ...user, [fieldName]: value };
      setUser(updatedUser);
    }
  };

  const handleToggle = () => {
    if (user) {
      const updatedUser = { ...user, status: !user?.is_active };
      setUser(updatedUser);
    }
  };

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={
        userInitial
          ? t("services.modifyService", "Mofify user")
          : t("services.addingANewService", "Adding a new service")
      }
      onClose={onClose}
      onSave={() => toast.success(t("common.changeSaved", "Change saved"))}
    >
      <div className={classes.containerText}>
        <CustomTextInput
          label={t("common.name", "Name")}
          placeholder={t("common.name", "Name")}
          marginBottom={"0px"}
          value={user?.lastname}
          onChange={(e) => handleInputChange("name", e.target.value)}
        />
        <CustomTextInput
          label={t("common.description", "Description")}
          placeholder={t("common.description", "Description")}
          marginBottom={"0px"}
          value={user?.firstname}
          onChange={(e) => handleInputChange("description", e.target.value)}
        />

        <div className={classes.divCheckBox}>
          <CustomTypography
            text={t("common.actived", "Actived")}
            variant={"default"}
            color={colors.darkblack}
          />
          {user?.is_active ? (
            <motion.div
              className={classes.divCheckBox}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.5 }}
            >
              <Icon
                onClick={handleToggle}
                name={"toggleLeft"}
                height={"24px"}
                width={"24px"}
                style={{ cursor: "pointer" }}
              />
            </motion.div>
          ) : (
            <motion.div
              className={classes.divCheckBox}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.5 }}
            >
              <Icon
                onClick={handleToggle}
                name={"toggleRight"}
                height={"24px"}
                width={"24px"}
                style={{ cursor: "pointer" }}
              />
            </motion.div>
          )}
          <CustomTypography
            text={t("common.deactived", "Deactived")}
            variant={"default"}
            color={colors.darkblack}
          />
        </div>
      </div>
    </CustomModal>
  );
};
