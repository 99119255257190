import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import { CustomTextInput } from "../../../components/CustomTextInput/CustomTextInput";
import useStyles from "./styles";
import { FC, useEffect, useState } from "react";
import { CustomDropZoneUpload } from "../../../components/CustomDropZoneUpload/CustomDropZoneUpload";
import { CustomCheckboxSquare } from "../../../components/CustomCheckboxSquare/CustomCheckboxSquare";
import { useCreateCompanie, useUpdateCompanie } from "../../../api/companies";
import toast from "react-hot-toast";
import { uploadImage } from "../../../utils/firebaseConfig";

type props = {
  open: boolean;
  compagnieInitial: TCompanie;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalAddSociety: FC<props> = ({
  open,
  compagnieInitial,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const initCompanie = () => {
    setUploadedFile(null);
    setCompanie({
      id: -1,
      name: "",
      registration_id: "",
      logo: "",
      is_fleet: 0,
      is_rental: 0,
    });
  };

  useEffect(() => {
    if (!open) {
      initCompanie();
    } else {
      setCompanie(compagnieInitial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { mutate: createCompanie, isPending: loadingCreateCompanie } =
    useCreateCompanie(onClose, initCompanie);

  const { mutate: updateCompanie, isPending: loadingUpdateCompanie } =
    useUpdateCompanie(onClose, initCompanie);

  const [companie, setCompanie] = useState<TCompanie>(compagnieInitial);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const onDrop = (acceptedFiles: File[]) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const handleInputChange = (fieldName: string, value: string | number) => {
    if (companie) {
      const updateCompanie = { ...companie, [fieldName]: value };
      setCompanie(updateCompanie);
    } else {
      const newCompanie: TCompanie = {
        id: -1,
        name: "",
        registration_id: "",
        logo: "",
        is_fleet: 0,
        is_rental: 0,
        [fieldName]: value,
      };
      setCompanie(newCompanie);
    }
  };

  const onSave = async () => {
    // Vérification des champs requis
    if (!companie.name?.trim().length) {
      toast.error(t("compagnies.requiredName", "Name is required"));
      return;
    }
    if (!companie.registration_id?.trim().length) {
      toast.error(t("compagnies.requiredSiret", "Siret is required"));
      return;
    }

    // Création de l'objet à envoyer
    const companieData = {
      id: companie?.id,
      logo: companie.logo,
      name: companie.name,
      registration_id: companie.registration_id,
      is_fleet: companie.is_fleet,
      is_rental: companie.is_rental,
    };

    // Envoi vers la base de données Firebase
    if (uploadedFile) {
      const url = (await uploadImage(
        URL.createObjectURL(uploadedFile)
      )) as string;
      companieData.logo = url || "";
      if (companie?.id > -1) {
        updateCompanie(companieData);
      } else {
        createCompanie(companieData);
      }
    } else {
      if (companie?.id > -1) {
        updateCompanie(companieData);
      } else {
        createCompanie(companieData);
      }
    }
  };

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={
        compagnieInitial?.id > -1
          ? t("compagnies.modify", "Modification of a company")
          : t("compagnies.add", "Adding a new company")
      }
      onClose={onClose}
      onSave={onSave}
      loadingSave={loadingCreateCompanie || loadingUpdateCompanie}
    >
      <div className={classes.containerText}>
        <div className={classes.divImage}>
          <CustomDropZoneUpload
            onDrop={onDrop}
            imageUrl={
              uploadedFile
                ? URL.createObjectURL(uploadedFile)
                : compagnieInitial.logo
            }
          />
        </div>
        <CustomTextInput
          label={`${t("common.name", "Name")} *`}
          placeholder={`${t("common.name", "Name")} *`}
          marginBottom={"0px"}
          value={companie?.name}
          onChange={(e) => handleInputChange("name", e.target.value)}
        />
        <CustomTextInput
          label={`Siret *`}
          placeholder={`Siret *`}
          marginBottom={"0px"}
          value={companie?.registration_id}
          onChange={(e) => handleInputChange("registration_id", e.target.value)}
        />
        <div className={classes.checkboxDiv}>
          <CustomCheckboxSquare
            text="Fleet"
            defaultChecked={compagnieInitial?.is_fleet === 1}
            onChange={(checked) =>
              handleInputChange("is_fleet", checked ? 1 : 0)
            }
          />
          <CustomCheckboxSquare
            defaultChecked={compagnieInitial?.is_rental === 1}
            text="Rental"
            onChange={(checked) =>
              handleInputChange("is_rental", checked ? 1 : 0)
            }
          />
        </div>
      </div>
    </CustomModal>
  );
};
