import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import useStyles from "./styles";
import { FC, ReactNode, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from "@mui/material";
import { CustomTextSelect } from "../../../components/CustomTextSelect/CustomTextSelect";
import { CustomLoadFileInput } from "../../../components/CustomLoadFileInput/CustomLoadFileInput";
import toast from "react-hot-toast";
import { useGetAllCompanies } from "../../../api/companies";
import { getOptionsFromObjects } from "../../../utils/utils";
import { useOwnedVehicules, useRentedVehicules } from "../../../api/vehicule";
import { CustomModalInfo } from "../../../components/CustomModalInfo/CustomModalInfo";
import { useParams } from "react-router-dom";

type props = {
  open: boolean;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalMassImport: FC<props> = ({ open, onCancel, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id = "" } = useParams();
  const companieId = parseInt(id);

  const { companies } = useGetAllCompanies();

  const [openInformationImport, setOpenInformationImport] = useState(false);
  const [dataResponseImport, setDataResponseImport] = useState(null);
  const [valueSelectedCompanie, setValueSelectedCompanie] = useState({
    label: "",
    value: "",
  });
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const [selectedLocation, setSelectedLocation] = useState<
    "location" | "society"
  >("society");

  const initValues = () => {
    setUploadedFile(null);
    setSelectedLocation("society");
    setValueSelectedCompanie({ label: "", value: "" });
    onClose && onClose();
  };

  const showMessageFromBack = (dataImport: any) => {
    setDataResponseImport(dataImport);
    setOpenInformationImport(true);
    initValues();
  };

  const { mutate: ownedVehicule, isPending: isPendingownedVehicule } =
    useOwnedVehicules(showMessageFromBack);

  const { mutate: rentVehicule, isPending: isPendingRentVehicule } =
    useRentedVehicules(showMessageFromBack);

  const onDrop = (acceptedFiles: File[]) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const onChangeSelectCompanie = (
    event: SelectChangeEvent<any>,
    _: ReactNode
  ) => {
    setValueSelectedCompanie(
      event.target.value as { label: string; value: string }
    );
  };

  const fileToFormData = (file: File) => {
    const formData = new FormData();
    if (selectedLocation === "location")
      formData.append(`lessee-vehicle-file`, file);
    if (selectedLocation === "society")
      formData.append(`lessor-vehicle-file`, file);
    return formData;
  };

  const onSave = async () => {
    if (!uploadedFile) {
      toast.error(t("required.fileRequired", "Please select a file"));
      return;
    } else {
      const newBinary = fileToFormData(uploadedFile);
      if (selectedLocation === "society") {
        newBinary.append("companyId", companieId.toString());
        ownedVehicule({ lessorVehicule: newBinary });
      } else {
        if (valueSelectedCompanie.value === "") {
          toast.error(
            t("required.allFieldsRequired", "All fields are required")
          );
          return;
        } else {
          if (newBinary) {
            newBinary.append(`lessee-companyId`, valueSelectedCompanie.value);
            newBinary.append(`rental-companyId`, companieId.toString());
            rentVehicule({
              lessee: newBinary,
            });
          }
        }
      }
    }
  };

  const onCancelModal = () => {
    onCancel && onCancel();
    initValues();
    setOpenInformationImport(false);
  };

  const DataDisplay = () => {
    const rows = String(dataResponseImport).split("\n").slice(1);
    return (
      <div>
        {rows.map((row, index) => (
          <div key={index}>{row}</div>
        ))}
      </div>
    );
  };

  return (
    <>
      <CustomModalInfo
        title="Informations sur l'import"
        open={openInformationImport}
        onClose={() => setOpenInformationImport(false)}
        width={"auto"}
        maxWidth={"100%"}
      >
        <div>{DataDisplay()}</div>
      </CustomModalInfo>
      <CustomModal
        open={open}
        onCancel={onCancelModal}
        title={t("car.massImport", "Mass import")}
        onClose={onClose}
        textConfirmation={t("common.resend", "Resend")}
        onSave={onSave}
        loadingSave={isPendingownedVehicule || isPendingRentVehicule}
      >
        <div className={classes.textModalContainer}>
          <FormControl className={classes.customRadio}>
            <RadioGroup
              defaultValue="society"
              value={selectedLocation}
              onChange={(e) => {
                setSelectedLocation(e.target.value as "location" | "society");
              }}
            >
              <FormControlLabel
                value="society"
                control={<Radio />}
                label={t("car.company", "For a company")}
              />
              <FormControlLabel
                value="location"
                control={<Radio />}
                label={`${t("car.location", "For a rental company")}`}
              />
            </RadioGroup>
          </FormControl>
          {selectedLocation === "location" && (
            <div className={classes.customSelect}>
              <CustomTextSelect
                label={`${t("car.selectionCompany", "Company selection")} *`}
                placeholder={t("common.company", "Company")}
                value={valueSelectedCompanie}
                onChange={onChangeSelectCompanie}
                options={getOptionsFromObjects(companies, "name", "id")}
              />
            </div>
          )}
          <CustomLoadFileInput fileName={uploadedFile?.name} onDrop={onDrop} />
        </div>
      </CustomModal>
    </>
  );
};
