import i18n from "../../i18n/i18n";

export const FREE = {
  label: "Désactivé",
  value: "FREE",
};

export const ACTIF = {
  label: "Activé",
  value: "ACTIF",
};

const statuses = [FREE, ACTIF];

export const generateFakeData = (rowCount: any) => {
  const fakeData = [];

  for (let i = 1; i <= rowCount; i++) {
    const fakeCar = {
      id: i,
      name: "SERVICE_NAME",
      description: "DESCRIPTION",
      status: statuses[Math.floor(Math.random() * statuses.length)],
    };
    fakeData.push(fakeCar);
  }

  return fakeData;
};

export const dataFilters = [
  {
    id: "1",
    name: i18n.t("common.actived", "Actived"),
    value: "ACTIF",
  },
  {
    id: "2",
    name: i18n.t("common.deactived", "Deactived"),
    value: "FREE",
  },
];
