import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import { CustomTextInput } from "../../../components/CustomTextInput/CustomTextInput";
import useStyles from "./styles";
import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useCreateDepartment,
  useUpdateDepartment,
} from "../../../api/departments";

type props = {
  open: boolean;
  companyId: number;
  departmentInitial: TDepartment;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalAddDepartment: FC<props> = ({
  open,
  companyId,
  departmentInitial,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [department, setDepartment] = useState<TDepartment>(departmentInitial);
  const { mutate: createDepartment, isPending } = useCreateDepartment(
    companyId,
    onClose,
    () => setDepartment({ id: -1, name: "" })
  );

  const { mutate: updateDepartment, isPending: loadingUpdate } =
    useUpdateDepartment(companyId, departmentInitial.id ?? -1, onClose, () =>
      setDepartment({ id: -1, name: "" })
    );

  useEffect(() => {
    if (departmentInitial) {
      setDepartment(departmentInitial);
    } else {
      setDepartment({ name: "" });
    }
  }, [departmentInitial]);

  const handleInputChange = (fieldName: string, value: string) => {
    if (department) {
      const updateDepartment = { ...department, [fieldName]: value };
      setDepartment(updateDepartment);
    } else {
      const newDepartment: TDepartment = {
        id: -1,
        name: "",
        [fieldName]: value,
      };
      setDepartment(newDepartment);
    }
  };

  const handleSave = () => {
    if (department?.name.trim().length === 0) {
      toast.error(t("required.lastname", "Name is required"));
      return;
    } else {
      if (departmentInitial.id !== -1) {
        updateDepartment({ name: department.name });
      } else {
        createDepartment({ name: department.name });
      }
    }
  };

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={
        departmentInitial.id !== -1
          ? t("department.modifyDepartment", "Modify department")
          : t("department.addDepartment", "Add department")
      }
      onClose={onClose}
      onSave={handleSave}
      loadingSave={isPending || loadingUpdate}
    >
      <div className={classes.containerText}>
        <CustomTextInput
          label={t("common.name", "Name")}
          placeholder={t("common.name", "Name")}
          marginBottom={"0px"}
          value={department?.name}
          onChange={(e) => handleInputChange("name", e.target.value)}
        />
      </div>
    </CustomModal>
  );
};
