import IconButton from "@mui/material/IconButton";
import { Icon } from "../Icon/Icon";
import { colors } from "../../styles/colors";
import useStyles from "./styles";
type Props = {
  iconName?: string;
  widthIcon?: string | number;
  heightIcon?: string | number;
  color?: any;
  onClick?: () => void;
  backgroundColor?: string;
};

export const CustomIconButton: React.FC<Props> = ({
  iconName,
  heightIcon,
  widthIcon,
  color = colors.WHITE,
  backgroundColor,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.icon}>
      <IconButton
        onClick={onClick}
        style={{
          width: "30px",
          height: "30px",
          backgroundColor: backgroundColor ? backgroundColor : colors.primary,
        }}
      >
        {iconName && (
          <Icon
            name={iconName}
            width={widthIcon}
            height={heightIcon}
            color={color}
          />
        )}
      </IconButton>
    </div>
  );
};
