import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  container: {
    position: "fixed",
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(0, 0, 0, 0.4)",
    zIndex: 999999999,
  },
});
export default useStyles;
