import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { Sidebar } from "../../containers/Sidebar/Sidebar";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";

import { FleetInfos } from "./FleetInfos/FleetInfos";
import { CustomInfos } from "./CustomInfos/CustomInfos";
import { colors } from "../../styles/colors";

const dataUsers = [
  { name: "Group A", value: 60 },
  { name: "Group B", value: 40 },
];
const dataCompagnies = [
  { name: "Group A", value: 40 },
  { name: "Group B", value: 60 },
];

type Props = {};

export const Dashboard: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Sidebar
        styles={{
          sidebarRightRoot: classes.vehiculeRoot,
          sidebarRoot: classes.contentMain,
        }}
      >
        <div className={classes.container}>
          <div className={classes.containerHeader}>
            <div>
              <CustomTypography
                variant="h2"
                text={t("dashboard.dashboard", "Dashboard")}
                lineHeight={"47px"}
                isUppercase
              />
            </div>
          </div>
          <div className={classes.containerBody}>
            <div>
              <CustomTypography
                text={t(
                  "dashboard.overviewOfActivities",
                  "Overview of activities"
                )}
                variant={"h4"}
                isUppercase
              />
            </div>
            <div className={classes.divScroll}>
              <div className={classes.containerCarsInfos}>
                <CustomInfos
                  lightColor={colors.lightpurple}
                  darkColor={colors.darkpurple}
                  label={t("common.individuals", "Individuals")}
                  numberOfUsers={4673}
                  iconName="car"
                  isShowGraph
                  colorGraph="#9747FF"
                  data={dataUsers}
                  purcent={"40%"}
                  key={t("common.individuals", "Individuals")}
                />
                <CustomInfos
                  lightColor={colors.lightBlue}
                  darkColor={colors.darkBlue}
                  label={t("common.compagnies", "Compagnies")}
                  iconName="car"
                  numberOfUsers={9673}
                  isShowGraph
                  colorGraph="#1784D2"
                  data={dataCompagnies}
                  purcent={"60%"}
                  key={t("common.compagnies", "Compagnies")}
                />
                <CustomInfos
                  lightColor={colors.orangeLight}
                  darkColor={colors.orangeDark}
                  label={t("common.appMobile", "App mobile")}
                  numberOfUsers={3987}
                  iconName="userCircle"
                  key={t("common.appMobile", "App mobile")}
                />
              </div>
              <FleetInfos />
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};
