import Axios from "axios";
import toast from "react-hot-toast";
import i18n from "../i18n/i18n";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Cookie: "XDEBUG_SESSION=PHPSTORM",
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: Number(process.env.REACT_APP_REQUEST_TIMEOUT),
});

axios.interceptors.request.use((config) => {
  // Read token for anywhere, in this case directly from localStorage
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["X-token-api"] = token;
  }
  const lang = localStorage.getItem("lang");
  if (lang) {
    // use config.params if it has been set
    config.params = config.params || {};
    // add any client instance specific params to config
    config.params["lang"] = lang;
  }

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.data.message.includes("DatabaseSessionUserProvider")
    ) {
      toast.error(
        i18n.t("login.sessionExpired", "Session expired, please login again")
      );
      localStorage.clear();
      window?.location?.reload();
    }
    return Promise.reject(error);
  }
);

export default axios;
