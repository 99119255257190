import { createUseStyles } from "react-jss";
const useStyles = createUseStyles((_) => ({
  containerText: {
    padding: "19px 15px 29px 15px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  divCheckBox: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    alignItems: "center",
  },

  divImage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  checkboxDiv: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    marginLeft: "24px",
  },
}));

export default useStyles;
