import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import { Icon } from "../Icon/Icon";
import { colors } from "../../styles/colors";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";

export const CustomSearchWithoutSolo = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <FormControl
      sx={{ width: "27ch" }}
      className={classes.formControl}
      variant="outlined"
    >
      <OutlinedInput
        placeholder={t("common.find", "Find")}
        endAdornment={
          <InputAdornment position="end">
            <Icon
              name="search"
              color={colors.darkblack}
              width={"15px"}
              height={"15px"}
            />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
