import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
  },
});

export default useStyles;
