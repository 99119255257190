import Dialog from "@mui/material/Dialog";
import useStyles from "./styles";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import { colors } from "../../styles/colors";
import { CustomIconButton } from "../CustomIconButton/CustomIconButton";
import { CustomButton } from "../CustomButton/CustomButton";
import { useTranslation } from "react-i18next";

type Props = {
  width?: string | number;
  children: React.ReactNode;
  title: string;
  open: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onSave?: () => void;
  isConfirmation?: boolean;
  textConfirmation?: string;
  onConfirm?: () => void;
  showButtonAction?: boolean;
  loadingSave?: boolean;
  loadingConfirmation?: boolean;
};

export const CustomModal: React.FC<Props> = ({
  width,
  children,
  title,
  open,
  isConfirmation,
  textConfirmation,
  onConfirm,
  onClose,
  onCancel,
  onSave,
  loadingSave,
  loadingConfirmation,
  showButtonAction = true,
}) => {
  const classes = useStyles({ width });
  const { t } = useTranslation();

  return (
    <Dialog open={open} className={classes.containerDialog}>
      <div className={classes.titleContainer}>
        <CustomTypography
          text={
            isConfirmation && title.trim().length === 0
              ? t("common.confirmation", "Confirmation")
              : title
          }
          variant={"h5"}
          color={colors.secondary}
          isUppercase
        />
        <CustomIconButton
          color={colors.BLACK}
          backgroundColor={colors.default}
          widthIcon={"25px"}
          heightIcon={"25px"}
          iconName="close"
          onClick={onClose}
        />
      </div>
      {children}
      {showButtonAction && (
        <div className={classes.buttonContainer}>
          <CustomButton
            label={t("common.cancel", "Cancel")}
            variant={"default"}
            textVariant={"link"}
            fontWeight={"bold"}
            width={"fit-content"}
            padding={"3px 16px"}
            onClick={onCancel}
          />
          {isConfirmation && textConfirmation ? (
            <CustomButton
              label={textConfirmation}
              variant={"secondary"}
              textVariant={"link"}
              fontWeight={"bold"}
              width={"fit-content"}
              padding={"3px 16px"}
              onClick={onConfirm}
              loading={loadingConfirmation}
            />
          ) : (
            <CustomButton
              label={t("common.save", "Save")}
              variant={"secondary"}
              textVariant={"link"}
              fontWeight={"bold"}
              width={"fit-content"}
              padding={"3px 16px"}
              onClick={onSave}
              loading={loadingSave}
            />
          )}
        </div>
      )}
    </Dialog>
  );
};
