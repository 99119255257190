import { useTranslation } from "react-i18next";
import { IconWithBackground } from "../../../components/IconWithBackground/IconWithBackground";
import { CustomBadgeText } from "../../../components/CustomBadgeText/CustomBadgeText";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { colors } from "../../../styles/colors";
import { motion } from "framer-motion";
import useStyles from "./styles";

export const InfosUsers = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <motion.div
      className={classes.divUsers}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.7 }}
    >
      <div className={classes.divUserTop}>
        <IconWithBackground
          iconName="coinEuro"
          backgroundColor={colors.pinkLight}
          iconColor={colors.pinkDark}
          width={"28px"}
          height={"28px"}
        />
        <CustomBadgeText
          label={t("dashboard.turnover", "Turnover")}
          backgroundColor={colors.pinkLight}
          colorText={colors.pinkDark}
        />
      </div>
      <div className={classes.divUserBottom}>
        <div className={classes.subDiv}>
          <CustomTypography text={"€ 318"} variant={"h2"} />
          <CustomTypography
            text={`/ ${t("common.day", "Day")}`}
            variant={"default"}
          />
        </div>
        <div className={classes.subDiv}>
          <CustomTypography text={"€ 1087"} variant={"h2"} />
          <CustomTypography
            text={`/ ${t("common.month", "Month")}`}
            variant={"default"}
          />
        </div>
        <div className={classes.subDiv}>
          <CustomTypography text={"€ 31987"} variant={"h2"} />
          <CustomTypography
            text={`/ ${t("common.year", "Year")}`}
            variant={"default"}
          />
        </div>
      </div>
    </motion.div>
  );
};
