import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../../containers/Sidebar/Sidebar";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { CustomTable } from "../../components/CustomTable/CustomTable";
import { colors } from "../../styles/colors";
import { CustomPreview } from "../../components/CustomPreview/CustomPreview";
import { useEffect, useRef, useState } from "react";
import { CustomButtonTable } from "../../components/CustomButtonTable/CustomButtonTable";
import { ModalMassImport } from "./ModalMassImport/ModalMassImport";
import { motion, AnimatePresence } from "framer-motion";
import { useGetAllVehicules, useMintVehicleAll } from "../../api/vehicule";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";
import { columnsCarsGeneric } from "./columnsCarsGeneric";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Icon } from "../../components/Icon/Icon";
import { useGetAllCompanies, useGetOneCompanie } from "../../api/companies";
import { CustomCheckboxSelected } from "../../components/CustomCheckboxSelected/CustomCheckboxSelected";
import { MenuItem } from "@mui/material";
import { dataFiltersStatusCarsGeneric, removeFilterCompany } from "./data";
import { getKeyLabelByValue } from "../../utils/types";
import { NO_FILTER } from "../../utils/utils";

type Props = {};

export const CarsGeneric: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const location = useLocation();

  const navigate = useNavigate();
  const { id = "" } = useParams();
  const companieId = parseInt(id);

  const { companie, isPending: loadingCompanie } =
    useGetOneCompanie(companieId);

  const { companies } = useGetAllCompanies();

  const [resetPagination, setResetPagination] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filtersSelectedCompany, setFiltersSelectedCompany] =
    useState<string>("");
  const [filtersSelected, setFiltersSelected] = useState<string>("");

  const { mutate: mintAllSelectedVehicle } = useMintVehicleAll();

  const { cars, isPending, totalCount } = useGetAllVehicules(
    currentPage,
    10,
    searchTerm,
    companie?.name
      ? companie?.name
      : filtersSelectedCompany === ""
      ? ""
      : filtersSelectedCompany,
    filtersSelected
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const containerRef = useRef<any>(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickAway);

    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, []);

  const handleClickAway = (event: any) => {
    if (
      containerRef.current &&
      !containerRef?.current?.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onSelectedRows = (selectRows: Record<string, any>[]) => {
    setSelectedRows(selectRows as any);
  };

  const onMintAll = () => {
    const vehicles = selectedRows?.map((row: any) => row.vehicleId);
    mintAllSelectedVehicle({ vehicles });
  };

  const handleChangePageProps = (newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeFiltersCompany = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    onResetTable();
    if (e.target.value === NO_FILTER) {
      setFiltersSelectedCompany("");
    } else {
      setFiltersSelectedCompany(e.target.value as any);
    }
  };

  const handleChangeFilters = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    onResetTable();
    setFiltersSelected(e.target.value as any);
  };

  const onResetTable = () => {
    setCurrentPage(1);
    setResetPagination(!resetPagination);
  };

  return (
    <>
      {isPending && <CustomLoader isActive={isPending} />}
      <ModalMassImport
        open={openModal}
        onClose={onCloseModal}
        onCancel={onCloseModal}
      />
      <Sidebar
        styles={{
          sidebarRightRoot: classes.vehiculeRoot,
          sidebarRoot: classes.contentMain,
        }}
      >
        <div className={classes.containerHeader}>
          {location.pathname.includes("/compagnies") && (
            <div className={classes.divIconArrow}>
              <Icon
                style={{ cursor: "pointer" }}
                name={"arrowLeft"}
                width={"28px"}
                height={"28px"}
                onClick={() => navigate(-1)}
              />
              {!loadingCompanie && (
                <CustomTypography
                  variant="h2"
                  text={companie?.name ?? ""}
                  isUppercase
                />
              )}
            </div>
          )}
          {!location.pathname.includes("/compagnies") && (
            <CustomTypography
              variant="h2"
              text={t("car.vehicleManagment", "Vehicle managment")}
              isUppercase
            />
          )}
          {location.pathname.includes("/compagnies") && (
            <CustomButtonTable
              variant={"primary"}
              textVariant={"link"}
              label={t("car.massImport", "Mass import")}
              width={"fit-content"}
              fontWeight={"bold"}
              textDecoration={"none"}
              iconName={"plus"}
              padding={"0px 15px"}
              textTransform="uppercase"
              onClick={() => setOpenModal(true)}
            />
          )}
        </div>
        <div className={classes.divApercu}>
          <CustomTypography
            text={t("common.preview", "Preview")}
            variant={"h5"}
            isUppercase
          />
          <div className={classes.divApercuFilter}>
            <div className={classes.divApercu2}>
              {!isPending && (
                <CustomPreview
                  label={`${t("car.numberOfVehicles", {
                    nbUsers: totalCount,
                  })}`}
                  iconName="userCircle"
                  backgroundIcon={colors.secondary}
                />
              )}
            </div>
            <div className={classes.divFilter}>
              <CustomTypography
                text={`${t("common.filter", "Filter")} :`}
                variant={"link"}
                fontWeight={"bold"}
                color={colors.darkblack}
              />
              {!location.pathname.includes("/compagnies") && (
                <CustomCheckboxSelected
                  onChange={handleChangeFiltersCompany}
                  value={filtersSelectedCompany}
                  SelectProps={{
                    multiple: false,
                    renderValue: (selecteds: any): string => {
                      return selecteds === NO_FILTER ? "" : selecteds;
                    },
                  }}
                >
                  {[...companies, removeFilterCompany].map((company) => (
                    <MenuItem key={company.id} value={company.name}>
                      {company.name}
                    </MenuItem>
                  ))}
                </CustomCheckboxSelected>
              )}

              <CustomCheckboxSelected
                onChange={handleChangeFilters}
                value={filtersSelected}
                SelectProps={{
                  multiple: false,
                  renderValue: (selecteds: any): string => {
                    return getKeyLabelByValue(selecteds) ?? "";
                  },
                }}
              >
                {dataFiltersStatusCarsGeneric
                  .filter((filter) =>
                    location.pathname.includes("/compagnies")
                      ? filter.value !== "NO_SOCIETY"
                      : true
                  )
                  .map((p) => (
                    <MenuItem key={p.id} value={p.value}>
                      {p.name}
                    </MenuItem>
                  ))}
              </CustomCheckboxSelected>
            </div>
          </div>
        </div>
        <CustomTable
          isFilterFromBackend
          columns={columnsCarsGeneric}
          data={cars}
          isSearchable
          showSelectedField
          tableMaxHeight={"520px"}
          onSelectRow={onSelectedRows}
          tablePagnationCount={totalCount}
          resetPaginationTrigger={resetPagination}
          handleChangePageProps={handleChangePageProps}
          onChangeSearchTerm={(e) => {
            setTimeout(() => {
              onResetTable();
              setSearchTerm(e.target.value);
            }, 1000);
          }}
          componentHeader={
            <div style={{ position: "relative" }} ref={containerRef}>
              <CustomButtonTable
                variant={"primary"}
                textVariant={"link"}
                label={t("common.groupeActions", "Groupe actions")}
                width={"fit-content"}
                fontWeight={"bold"}
                textDecoration={"none"}
                iconName={"chevronDown"}
                padding={"0px 15px"}
                textTransform="uppercase"
                endIcon
                onClick={() => setOpen(true)}
              />
              <AnimatePresence>
                {open && (
                  <motion.div
                    className={classes.divAway}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.5 }}
                    onClick={handleClickAway}
                  >
                    <div className={classes.divAwayCustom} onClick={onMintAll}>
                      <CustomTypography
                        text={t("common.mintAll", "Mint all")}
                        variant={"link"}
                      />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          }
        />
      </Sidebar>
    </>
  );
};
