import { colors } from "../../styles/colors";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { IconWithBackground } from "../../components/IconWithBackground/IconWithBackground";
import compagniesDefault from "../../assets/png/compagniesDefault.png";
import { motion, AnimatePresence } from "framer-motion";
import useStyles from "./styles";
import { FC, useEffect, useRef, useState } from "react";
import { Icon } from "../../components/Icon/Icon";
import { useTranslation } from "react-i18next";
import { ModalDeleteSociety } from "./ModalDeleteSociety/ModalDeleteSociety";
import { useNavigate } from "react-router-dom";
import { ModalAddSociety } from "./ModalAddSociety/ModalAddSociety";
type props = {
  companie: TCompanie;
};

export const CustomCreateSociety: FC<props> = ({ companie }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const containerRef = useRef<any>(null);
  const navigate = useNavigate();

  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [modifyModal, setModifyModal] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickAway);

    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, []);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = (event: any) => {
    if (
      containerRef.current &&
      !containerRef?.current?.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  const onClickDelete = () => {
    setOpenModalDelete(true);
  };

  const onClickModify = () => {
    setModifyModal(true);
  };

  const onClickUsers = () => {
    navigate(`users/${companie.id}`);
  };

  const onhandleClose = () => {
    setOpenModalDelete(false);
    setModifyModal(false);
  };

  const onClickDepartment = () => {
    navigate(`${companie.id}/departments`);
  };

    const onClickVehicles = () => {
    navigate(`vehicules/${companie.id}`);
  };

  return (
    <>
      <ModalDeleteSociety
        companie={companie}
        open={openModalDelete}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />
      <ModalAddSociety
        compagnieInitial={
          modifyModal
            ? companie
            : {
                id: -1,
                name: "",
                registration_id: "",
                logo: "",
                is_fleet: 0,
                is_rental: 0,
              }
        }
        open={modifyModal}
        onCancel={onhandleClose}
        onClose={onhandleClose}
      />

      <div className={classes.divSociety}>
        <img
          src={companie?.logo ? companie?.logo : compagniesDefault}
          alt="compagnies"
          width={"36px"}
          height={"36px"}
        />
        <div className={classes.divText}>
          <CustomTypography
            text={companie.name}
            variant={"default"}
            fontWeight={"bold"}
            color={colors.darkblack}
          />
          <CustomTypography
            text={`SIRET : ${companie.registration_id}`}
            variant={"default"}
            color={colors.darkblack}
          />
        </div>
        <div style={{ position: "relative" }} ref={containerRef}>
          <div className={classes.divIcon} onClick={handleClick}>
            <IconWithBackground
              iconName={"dotsVertical"}
              widthBackground={"34px"}
              heightBackground={"34px"}
              backgroundColor={colors.default}
              width={"24px"}
              height={"24px"}
            />
          </div>
          {/* code without mui */}
          <AnimatePresence>
            {open && (
              <motion.div
                className={classes.divAway}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
                onClick={handleClickAway}
              >
                <div className={classes.divAway2} onClick={onClickDepartment}>
                  <Icon
                    name={"buildingSkyscraper"}
                    width={"12px"}
                    height={"12px"}
                    color={colors.darkblack}
                  />
                  <CustomTypography
                    text={t("department.department", "Department")}
                    variant={"link"}
                  />
                </div>
                <div className={classes.divAway2} onClick={onClickVehicles}>
                  <Icon
                    name={"car"}
                    width={"12px"}
                    height={"12px"}
                    color={colors.darkblack}
                  />
                  <CustomTypography
                    text={t("common.vehicle", "Vehicles")}
                    variant={"link"}
                  />
                </div>
                <div className={classes.divAway2} onClick={onClickUsers}>
                  <Icon
                    name={"userCircle"}
                    width={"12px"}
                    height={"12px"}
                    color={colors.darkblack}
                  />
                  <CustomTypography
                    text={t("common.users", "Users")}
                    variant={"link"}
                  />
                </div>
                <div className={classes.divAway2} onClick={onClickModify}>
                  <Icon
                    name={"pencil"}
                    width={"12px"}
                    height={"12px"}
                    color={colors.darkblack}
                  />
                  <CustomTypography
                    text={t("common.modify", "Modify")}
                    variant={"link"}
                  />
                </div>
                <div className={classes.divAway2} onClick={onClickDelete}>
                  <Icon
                    name={"trash"}
                    width={"12px"}
                    height={"12px"}
                    color={colors.tertiary}
                  />
                  <CustomTypography
                    text={t("common.delete", "Delete")}
                    variant={"link"}
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};
