import { FC } from "react";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { Icon } from "../../../components/Icon/Icon";
import useStyles from "./styles";

type MenuItemProps = {
  iconName: string;
  text: string;
  width: number | string;
  height: number | string;
  isCollapsed: boolean;
  onClick?: () => void;
  inverse?: boolean;
};

export const MenuItem: FC<MenuItemProps> = ({
  iconName,
  isCollapsed,
  text,
  width,
  height,
  onClick,
  inverse,
}) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.menuItem}`}
      style={{
        width: isCollapsed ? 200 : 40,
        justifyContent: isCollapsed ? "start" : "center",
        paddingLeft: isCollapsed ? 10 : 0,
      }}
      onClick={onClick}
    >
      {inverse ? (
        <div className={classes.inverseMenu}>
          {isCollapsed && (
            <CustomTypography text={text} variant={"h5"} isUppercase />
          )}
          <Icon name={iconName} width={width} height={height} />
        </div>
      ) : (
        <>
          <Icon name={iconName} width={width} height={height} />
          {isCollapsed && (
            <CustomTypography text={text} variant={"h5"} isUppercase />
          )}
        </>
      )}
    </div>
  );
};
