import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import useStyles from "./styles";
import { FC } from "react";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";

type props = {
  open: boolean;
  userInitial: TUserGeneric | null;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalChangeStatus: FC<props> = ({
  open,
  userInitial,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={""}
      onClose={onClose}
      isConfirmation
      textConfirmation={t("common.validate", "Validate")}
      onConfirm={() => {}}
    >
      <div className={classes.textModalContainer}>
        <CustomTypography
          text={t("services.activeService", {
            name: userInitial?.lastname,
            firstname: userInitial?.firstname,
          })}
          variant="default"
        />
      </div>
    </CustomModal>
  );
};
