import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import useStyles from "./styles";
import { FC } from "react";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { useDeleteCompanie } from "../../../api/companies";

type props = {
  open: boolean;
  companie: TCompanie;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalDeleteSociety: FC<props> = ({
  open,
  companie,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { mutate: deleteCompanie, isPending } = useDeleteCompanie(companie.id);

  const onConfirm = () => {
    deleteCompanie(companie.id);
  };

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={""}
      onClose={onClose}
      isConfirmation
      loadingSave
      textConfirmation={t("login.newPassword", "Validate")}
      loadingConfirmation={isPending}
      onConfirm={onConfirm}
    >
      <div className={classes.textModalContainer}>
        <CustomTypography
          text={t("compagnies.deleteMessage", {
            name: companie.name,
          })}
          variant="default"
        />
      </div>
    </CustomModal>
  );
};
