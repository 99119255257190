import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  divUsers: {
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    borderRadius: "15px",
    padding: "30px",
    backgroundColor: colors.WHITE,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.09)",
  },
  divUserTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  divUserBottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subDiv: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));

export default useStyles;
