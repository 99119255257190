import { FC } from "react";
import { Icon } from "../../../components/Icon/Icon";
import { colors } from "../../../styles/colors";
import useStyles from "./styles";

type props = {
  handleEditDepartment?: () => void;
  handleDeleteDepartment?: () => void;
};

export const ActionsTable: FC<props> = ({
  handleEditDepartment,
  handleDeleteDepartment,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.divContainer}>
      <Icon
        name="pencil"
        color={colors.darkblack}
        height={"24px"}
        width={"24px"}
        style={{ cursor: "pointer" }}
        onClick={handleEditDepartment}
      />
      <Icon
        name="trash"
        color={colors.red}
        height={"24px"}
        width={"24px"}
        onClick={handleDeleteDepartment}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};
