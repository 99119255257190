import { FC } from "react";
import useStyles from "./styles";

type props = {
  year: number;
  onClick?: () => void;
  isSelected: boolean;
};

export const MenuYearItem: FC<props> = ({ year, onClick, isSelected }) => {
  const classes = useStyles();
  return (
    <div
      className={isSelected ? classes.isMenuYearSelected : undefined}
      onClick={onClick}
    >
      {year}
    </div>
  );
};
