import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "./axios";
import toast from "react-hot-toast";

export const vehiculesKeys = {
  all: (
    page: number,
    per_page: number,
    filter: string,
    company: string,
    status: string,
    bc_status: string
  ) =>
    [
      "vehicles",
      "page",
      page,
      "per_page",
      per_page,
      "filter",
      filter,
      "company",
      company,
      "status",
      status,
      "bc_status",
      bc_status,
    ] as const,
};

interface ResponseGetVehicules {
  data: {
    vehicles: {
      data: TVehicule[];
      total: number;
    };
  };
}

export const useGetAllVehicules = (
  page: number = 1,
  per_page: number = 10,
  filter: string = "",
  company: string = "",
  status: string = "",
  bc_status: string = ""
) => {
  const { data, error, ...res } = useQuery({
    queryKey: vehiculesKeys.all(
      page,
      per_page,
      filter,
      company,
      status,
      bc_status
    ),
    queryFn: (): Promise<ResponseGetVehicules> =>
      axios.get(`/admin/vehicles`, {
        params: {
          page,
          per_page,
          searchBar: filter.trim().length > 0 ? filter : undefined,
          company: company.trim().length > 0 ? company : undefined,
          status: status.trim().length > 0 ? status : undefined,
          // à . tester si ça marche dans insomnia
          bc_status: bc_status.trim().length > 0 ? bc_status : undefined,
        },
      }),
    staleTime: 0,
  });
  if (error) {
  }
  return {
    cars: data?.data?.vehicles?.data ?? [],
    totalCount: data?.data?.vehicles?.total,
    ...res,
  };
};

interface RequestOwnedVehicules {
  lessorVehicule: FormData;
}

// ONLY_SUPER_ADMIN : Import owned vehicles
export const useOwnedVehicules = (
  onSave?: (data: any, fromError: boolean) => void
) => {
  const { refetch } = useGetAllVehicules();
  return useMutation({
    mutationFn: async (ownedVehicule: RequestOwnedVehicules) => {
      const data = await axios.post(
        `/admin/owned-vehicles`,
        ownedVehicule.lessorVehicule,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data.data;
    },
    onSuccess: (data) => {
      // toast.success(t("common.importSuccess", "Import successfully"));
      refetch();
      onSave && onSave(data, false);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
      onSave && onSave(err, true);
    },
  });
};

interface RequestRentedVehicules {
  lessee: FormData;
}

export const useRentedVehicules = (
  onSave?: (data: any, formError: boolean) => void
) => {
  const { refetch } = useGetAllVehicules();
  return useMutation({
    mutationFn: async (lesseeVehicule: RequestRentedVehicules) => {
      const data = await axios.post(
        `/admin/rented-vehicles`,
        lesseeVehicule.lessee,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return data.data;
    },
    onSuccess: (data) => {
      refetch();
      onSave && onSave(data, false);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
      // onSave && onSave(err, true);
    },
  });
};

export const useUpdateMintStatus = () => {
  return useMutation({
    mutationFn: async (vehicleId: number) => {
      const data = await axios.put(`/vehicle/${vehicleId}/mint-status`);
      return data.data;
    },
    onSuccess: (_) => {
      toast.success("Message à definir");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export const useMintVehicle = () => {
  return useMutation({
    mutationFn: async (vehicleId: number) => {
      const data = await axios.post(`/vehicle/${vehicleId}/mint`);
      return data.data;
    },
    onSuccess: (_) => {
      toast.success("Vehicule minter avec success");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

interface RequestMintVehicleAll {
  vehicles: number[];
}

export const useMintVehicleAll = () => {
  const { refetch } = useGetAllVehicules();
  return useMutation({
    mutationFn: async (vehicles: RequestMintVehicleAll) => {
      const data = await axios.put(
        `/admin/vehicles/mark-for-minting`,
        vehicles
      );
      return data.data;
    },
    onSuccess: (_) => {
      toast.success("Vehicule minter avec success");
      refetch();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export const useMintFailedToValidatedVehicleAll = () => {
  const { refetch } = useGetAllVehicules();
  return useMutation({
    mutationFn: async (vehicles: RequestMintVehicleAll) => {
      const data = await axios.put(`/event/bc-status`, vehicles);
      return data.data;
    },
    onSuccess: (_) => {
      toast.success("Vehicule à minter avec success");
      refetch();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};
