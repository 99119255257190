import { Link, To } from "react-router-dom";
import useStyles from "./styles";
import classNames from "classnames";
import { Box } from "@mui/material";
type Props = {
  text: string;
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "link" | "default";
  to?: To | undefined;
  isUppercase?: boolean;
  styles?: any;
  fontWeight?: string;
  textDecoration?: string;
  textAlign?: string;
  lineHeight?: string;
  color?: string;
  onClick?: () => void;
};

export const CustomTypography: React.FC<Props> = ({
  text,
  variant,
  to,
  isUppercase,
  styles,
  fontWeight,
  textDecoration,
  textAlign,
  lineHeight,
  color,
  onClick,
}) => {
  const classes = useStyles({
    fontWeight,
    textDecoration,
    textAlign,
    lineHeight,
    color,
  });

  let className;
  switch (variant) {
    case "h1":
      className = classes.h1;
      break;
    case "h2":
      className = classes.h2;
      break;
    case "h3":
      className = classes.h3;
      break;
    case "h4":
      className = classes.h4;
      break;
    case "h5":
      className = classes.h5;
      break;
    case "link":
      className = classes.link;
      break;
    default:
      className = classes.default;
      break;
  }

  return (
    <Box
      className={classNames(
        className,
        styles?.containerText,
        isUppercase && classes.uppercase
      )}
      onClick={onClick}
    >
      {variant === "link" && to ? (
        <Link
          className={classNames(
            className,
            styles?.containerText,
            isUppercase && classes.uppercase
          )}
          to={to}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: text }} />
      )}
    </Box>
  );
};
